import { GenericCard, JoinLoyaltyBanner, Switch } from '@dominos/components'
import { TermsAndConditionsGeneric } from '@dominos/components/terms-and-conditions'
import { useCustomer, useFeaturesV2 } from '@dominos/hooks-and-hocs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { getLoyaltyTranslations } from '../loyalty-translations'
import css from '../loyalty.less'
import { LoyaltyMarketing } from '../marketing'

interface LoyaltyEnrollProps {
  enrolled: boolean
  showLoyaltyMarketing?: boolean
  onCheck: (checked: boolean) => void
  receiveUpdatesEnabled: boolean
  onCheckReceiveUpdates: (checked: boolean) => void
}

export const LoyaltyEnroll: React.FC<LoyaltyEnrollProps> = ({
  enrolled,
  receiveUpdatesEnabled,
  showLoyaltyMarketing = true,
  onCheck,
  onCheckReceiveUpdates,
}) => {
  const { t } = useTranslation('loyalty')
  const translations = getLoyaltyTranslations(t)
  const { isLoyaltyEnrolled, isLoyaltyMarketingEnrolled } = useCustomer()
  const [isSingleOptInEnabled] = useFeaturesV2('LoyaltySingleOptIn')

  const onJoinSwitch = (_: string, checked: boolean) => {
    onCheck(checked)
    if (isSingleOptInEnabled) {
      onCheckReceiveUpdates(checked)

      return
    }

    if (!checked) {
      onCheckReceiveUpdates(false)
    }
  }

  if (isLoyaltyEnrolled && isLoyaltyMarketingEnrolled) {
    return null
  }

  const receiveUpdatesDisabledState = isLoyaltyEnrolled ? false : !enrolled

  return (
    <GenericCard
      noPadding
      testID='LoyaltyEnroll'
      style={{
        maxWidth: 'none',
        border: 'solid 0.1px rgba(151, 151, 151, 0.4)',
        boxShadow: '0',
        marginBottom: '10px',
        marginTop: '10px',
      }}
    >
      <div className={css.loyaltyEnroll}>
        {!isLoyaltyEnrolled && (
          <JoinLoyaltyBanner testID={'Loyalty.JoinLoyaltyBanner'}>
            <TermsAndConditionsGeneric testID='Loyalty.TC' text={translations['LoyaltyT&C']} />
            <div className={css.joinSwitch}>
              <Switch testID={'Loyalty.JoinSwitch'} name='isEnrolled' checked={enrolled} onChange={onJoinSwitch}>
                <span className={css.switchLabel}>{translations.JoinLoyaltySwitch}</span>
              </Switch>
            </div>
          </JoinLoyaltyBanner>
        )}
        {showLoyaltyMarketing && (
          <LoyaltyMarketing
            testID={'Loyalty.ReceiveUpdates'}
            value={receiveUpdatesEnabled}
            disabled={receiveUpdatesDisabledState}
            onChange={onCheckReceiveUpdates}
          />
        )}
      </div>
    </GenericCard>
  )
}
