import React from 'react'
import { BasketRewards, createBasketRewardsViewModel } from 'olo-feature-loyalty'
import { LoyaltyCard } from '../../basket/basket-container/loyalty-card'
import { useAuthenticationPopup, useCustomer, useFeaturesV2, useLaunchDarklyFlags } from '@dominos/hooks-and-hocs'
import { useTranslation } from 'react-i18next'
import { isNativeApp, notifyNativeApp } from '@dominos/business/functions'

export const LoyaltyBasketRewards = () => {
  const { t } = useTranslation('loyalty')
  const { showPopup } = useAuthenticationPopup()
  const [loyaltyFeatureEnabled, loyaltyV2Enabled] = useFeaturesV2('loyalty-backend', 'LoyaltyV2Enabled')
  const { isLoggedIn } = useCustomer()
  const { flags } = useLaunchDarklyFlags()

  if (loyaltyV2Enabled && !isLoggedIn) {
    const viewModel = createBasketRewardsViewModel({
      onCreateAccountClick: () => (isNativeApp() ? notifyNativeApp('login') : showPopup('register')),
      onLoginClick: () => (isNativeApp() ? notifyNativeApp('login') : showPopup('login')),
      isLoggedIn: false,
      t,
      isFeatureEnabled: (feature: AllowedBooleanFeatures) => flags[feature],
    })

    return <BasketRewards viewModel={viewModel} />
  }

  return loyaltyFeatureEnabled ? <LoyaltyCard /> : null
}
