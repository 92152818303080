import React from 'react'
import uuid from 'uuid'
import { LaunchDarklyReadiness } from './launch-darkly-readiness'
import { LaunchDarklyStartup } from './launch-darkly-startup'
import { useLaunchDarklyID } from './use-launch-darkly-id'

interface LaunchDarklyContextProps {
  launchDarklyID: string
}

const LaunchDarklyContext = React.createContext<LaunchDarklyContextProps>({
  launchDarklyID: uuid(),
})

export const LaunchDarklyProvider = ({ children }: PropsWithChildren) => {
  const { setLaunchDarklyID, launchDarklyID } = useLaunchDarklyID()

  if (!launchDarklyID) {
    setLaunchDarklyID(uuid())
  }

  return (
    <LaunchDarklyContext.Provider value={{ launchDarklyID }}>
      <LaunchDarklyStartup launchDarklyID={launchDarklyID}>
        <LaunchDarklyReadiness>{children}</LaunchDarklyReadiness>
      </LaunchDarklyStartup>
    </LaunchDarklyContext.Provider>
  )
}

export const useLaunchDarklyContext = () => React.useContext(LaunchDarklyContext)
