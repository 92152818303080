import React from 'react'

import { useFeaturesV2, useServiceSubTypes } from '@dominos/hooks-and-hocs'
import { CustomerCarDetails, ServiceMethodSubTypes } from '@dominos/interfaces/checkout'

import { ServiceMethodType } from './service-method-type'

export const DEFAULT_PICKUP_SUBTYPE: ServiceMethodSubTypes = 'InStorePickUp'

export const PickUpType = (props: {
  checkedOption?: ServiceMethodSubTypes
  initialValue?: CustomerCarDetails
  onChange?: (fieldName: string | null, value: string | boolean | undefined) => void
  onChecked: (x?: Bff.Stores.ServiceMethodSubTypes) => void
  onFormValidationChange: (x: boolean) => void
}) => {
  const { pickupSubTypes } = useServiceSubTypes()
  const [pickUpTypesEnabled] = useFeaturesV2('EnablePickupSubTypes')

  return pickUpTypesEnabled ? (
    <ServiceMethodType
      fieldId='pick-up-type'
      fieldName='pickupSubType'
      serviceMethod='Pickup'
      defaultOption={DEFAULT_PICKUP_SUBTYPE}
      subTypes={[DEFAULT_PICKUP_SUBTYPE, ...pickupSubTypes]}
      checkedOption={props.checkedOption}
      onChecked={props.onChecked}
      onFormValidationChange={props.onFormValidationChange}
      onChange={props.onChange}
      initialValue={props.initialValue}
    />
  ) : null
}
