import { useFeaturesV2 } from '@dominos/hooks-and-hocs'
import React from 'react'
import { WhensoGPS } from './whenso-gps'
import { NextGenGPS } from './nextgen-gps'
import { getCountryConfig } from '@dominos/business/functions/common'

interface GPSTrackerProps {
  id?: string
  order: Bff.Orders.Order
  serviceMethod: string
}

export const GPSTracker = ({ id, order, serviceMethod }: GPSTrackerProps) => {
  const [nextgenGpsDriverTrackerEnabled, whensoGpsDriverTrackerEnabled] = useFeaturesV2(
    'gps-driver-tracker',
    'WhensoGpsDriverTracker',
  )
  const { NEXTGEN_GPS_DRIVER_TRACKER_URL, GPS_DRIVER_TRACKER_URL } = getCountryConfig()

  if (serviceMethod !== 'Delivery') {
    return null
  }

  if (nextgenGpsDriverTrackerEnabled) {
    return <NextGenGPS id={id} order={order} url={NEXTGEN_GPS_DRIVER_TRACKER_URL} />
  }

  if (whensoGpsDriverTrackerEnabled) {
    return <WhensoGPS id={id} order={order} url={GPS_DRIVER_TRACKER_URL} />
  }

  return null
}
