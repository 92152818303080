import { useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { ApolloError } from '@apollo/client/errors'

import { useFeaturesV2, useRecaptcha } from '@dominos/hooks-and-hocs'
import { resetPasswordRequestQuery, ResetPasswordResponse } from '@dominos/business/queries'

export interface ResetPasswordArgs {
  email: string
  useRecaptchaV3?: boolean
  recaptchaToken?: string
}

export const useForgotPassword = (
  onCompleted?: (data: ResetPasswordResponse['resetPasswordRequest']) => void,
  onError?: (error: ApolloError) => void,
) => {
  const [useRecaptchaV2, setUseRecaptchaV2] = useState(false)
  const [recaptchaEnabled] = useFeaturesV2('Recaptcha')
  const { executeV3, getV2Token, renderCheckboxV2, resetCheckboxV2 } = useRecaptcha()

  const [sendRequest, { loading, error }] = useLazyQuery<ResetPasswordResponse, ResetPasswordArgs>(
    resetPasswordRequestQuery,
    {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        const { resetPasswordRequest } = data
        if (resetPasswordRequest.shouldDisplayRecaptchaV2) {
          useRecaptchaV2 ? resetCheckboxV2() : setUseRecaptchaV2(true)
        } else {
          setUseRecaptchaV2(false)
          onCompleted?.(resetPasswordRequest)
        }
      },
      onError,
    },
  )

  const performForgotPasswordRequest = async function (email: string) {
    const variables: ResetPasswordArgs = {
      email,
    }
    if (recaptchaEnabled) {
      const token = useRecaptchaV2 ? getV2Token() : await executeV3('ResetPassword')
      variables.recaptchaToken = token
      variables.useRecaptchaV3 = !useRecaptchaV2
    }
    await sendRequest({ variables })
  }

  return {
    useRecaptchaV2,
    loading,
    error,
    renderCheckboxV2,
    performForgotPasswordRequest,
  }
}
