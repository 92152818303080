import { ComponentProps, useMemo } from 'react'
import { ApolloError } from '@apollo/client'
import { useFeaturesV2 } from '@dominos/hooks-and-hocs'
import { useCharacterLimit } from '@dominos/hooks-and-hocs'
import {
  AddressSearchResults,
  ERROR_INVALID_GOOGLE_ADDRESS_COMPONENT,
  ERROR_NO_CLOSEST_GEO_CODED_LOCATION,
  ERROR_NO_RESULTS_DELIVERY,
  ERROR_NO_RESULTS_PICKUP,
  ERROR_SERVER_ERROR_DELIVERY,
  ERROR_SERVER_ERROR_PICKUP,
} from '@dominos/components'
import { IGeolocation, useReportAutoCompleteDelivery } from 'olo-feature-address'

export interface useErrorCodeProps {
  type: ComponentProps<typeof AddressSearchResults>['type']
  predictions?: AddressLine[] | undefined
  storeDetailsServerError?: ApolloError | undefined
  storeDetails?: StoreDetails | undefined
  autoCompleteError?: ValidateAddressComponent | undefined
  input?: string | undefined
  searchIsCompleted?: boolean
  formError?: string | undefined
  geoLocation?: IGeolocation | undefined
}

/**
 * Custom hook to calculate the error code
 */
export const useErrorCode = (props: useErrorCodeProps) => {
  const avgAddressCharacters = useCharacterLimit()
  const reportAutoComplete = useReportAutoCompleteDelivery()
  const [autoCompleteDeliveryHideMaxCharacterError] = useFeaturesV2('AutoCompleteDeliveryHideMaxCharacterError')
  const hasNoPredictions = (predictions: AddressLine[] | undefined) => !predictions || predictions.length < 1

  return useMemo(() => {
    if (props.formError) {
      return props.formError
    }

    if (props.storeDetailsServerError) {
      if (props.type == 'Delivery') {
        reportAutoComplete.reportStoreDetailsError(props.input, props.storeDetailsServerError?.message)

        return ERROR_SERVER_ERROR_DELIVERY
      } else {
        return ERROR_SERVER_ERROR_PICKUP
      }
    }

    if (props.storeDetails?.errorCode) {
      reportAutoComplete.reportStoreDetailsError(props.input, props.storeDetails?.errorCode)

      return props.storeDetails.errorCode
    }

    if (props.autoCompleteError) {
      if (
        props.autoCompleteError.reason?.toLocaleLowerCase() === ERROR_NO_CLOSEST_GEO_CODED_LOCATION.toLocaleLowerCase()
      ) {
        reportAutoComplete.reportNoAddressFoundSelection(props.geoLocation)

        return ERROR_NO_CLOSEST_GEO_CODED_LOCATION
      }

      reportAutoComplete.reportSelectedAddressIncomplete(props.input)

      return ERROR_INVALID_GOOGLE_ADDRESS_COMPONENT + `:${props.autoCompleteError.reason}`
    }

    if (
      hasNoPredictions(props.predictions) &&
      !props.searchIsCompleted &&
      props.input &&
      props.input.length >= avgAddressCharacters
    ) {
      if (props.type == 'Delivery') {
        if (!autoCompleteDeliveryHideMaxCharacterError) {
          reportAutoComplete.reportNoSuggestionsFound(props.input)

          return ERROR_NO_RESULTS_DELIVERY
        } else {
          reportAutoComplete.reportNoSuggestionsFound(props.input)

          return undefined
        }
      } else {
        return ERROR_NO_RESULTS_PICKUP
      }
    }

    if (props.predictions) {
      return undefined
    }

    return undefined
  }, [
    props.storeDetailsServerError,
    props.storeDetails,
    props.predictions,
    props.input,
    props.autoCompleteError,
    props.formError,
  ])
}
