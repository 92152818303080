import React, { ComponentProps, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { GeoLocationInterface } from '@dominos/interfaces'
import { AddressSearchResults, DeliverySearchToolTip, GenericCard, ValidationTextField } from '@dominos/components'
import { convertAddressLineToIAddress, useCountryCode, useFeaturesV2, useGeocoder } from '@dominos/hooks-and-hocs'
import { AddressSearchStoredList } from '../address-search-stored-list'
import { MapListToggle } from './map-list-toggle'
import { CurrentLocationDisplayField } from '../current-location-display-field/current-location-display-field'
import { useAddressSearchValidation } from '@dominos/hooks-and-hocs'
import css from './delivery-address-search.less'
import { useReportAutoCompleteDelivery } from 'olo-feature-address'
import { getCountryConfig } from '@dominos/business/functions/common/get-config'
import { getConfigTypes } from '@dominos/hooks-and-hocs/location/use-auto-complete/providers/google/get-config-types'

export interface DeliveryAddressSearchProps extends BaseProps {
  title: ComponentProps<typeof GenericCard>['title']
  placeholder: ComponentProps<typeof ValidationTextField>['placeholder']
  searchMode?: 'List' | 'Map'
  detectedLocation?: GeoLocationInterface | undefined

  input: string | undefined
  isLoading: boolean
  predictions: AddressLine[] | undefined
  isError: boolean

  onTitleTriggerClick: () => void
  onSearchChange: (value: string | undefined, isValid: boolean) => void
  onSelectAddress: (address: AddressLine) => void
  onResetStoreDetails: () => void
}

const DELIVERY_SERVICE_METHOD = 'Delivery'

export const DeliveryAddressSearch = (props: DeliveryAddressSearchProps) => {
  const {
    testID,
    title,
    placeholder,
    searchMode,
    detectedLocation,
    input,
    isLoading,
    predictions,
    isError,
    onTitleTriggerClick,
    onSearchChange,
    onSelectAddress,
    onResetStoreDetails,
  } = props

  const [selectedAddress, setSelectedAddress] = useState<AddressLine | undefined>()
  const [displayCurrentLocation, setDisplayCurrentLocation] = useState<boolean>()
  const [detectedLocationAddressLine, setDetectedLocationAddressLine] = useState<AddressLine>()
  const countryCode = useCountryCode()
  const { reportSelectedCurrentLocation, reportSelectedAutoCompleteAddress } = useReportAutoCompleteDelivery()
  const { validationRules } = useAddressSearchValidation()
  const { addressLine: addressFromGeocoder, reverseGeocodeLocation } = useGeocoder()
  const { GOOGLE_PLACES_DELIVERY_TYPES } = getCountryConfig()
  const googlePlacesTypes = getConfigTypes(GOOGLE_PLACES_DELIVERY_TYPES)
  const searchAddressInput = useSelector((state: RootReducer) => state.customerReducer.searchAddressInput)
  const [autoCompleteListMapDeliveryAddress, autoCompleteDeliveryDisplayMapListToggle] = useFeaturesV2(
    'AutoCompleteListMapDeliveryAddress',
    'AutoCompleteDeliveryDisplayMapListToggle',
  )

  useEffect(() => {
    if (!detectedLocationAddressLine) return

    enableCurrentLocation()
  }, [detectedLocationAddressLine])

  useEffect(() => {
    if (!detectedLocation) return
    reverseGeocodeLocation(detectedLocation, googlePlacesTypes)
  }, [detectedLocation])

  useEffect(() => {
    if (!addressFromGeocoder) return
    setDetectedLocationAddressLine(addressFromGeocoder)
  }, [addressFromGeocoder])

  const enableCurrentLocation = () => {
    setDisplayCurrentLocation(true)
  }

  const disableCurrentLocation = () => {
    setDisplayCurrentLocation(false)
    setSelectedAddress(undefined)
    onResetStoreDetails()
  }

  const handleChange = async (updatedField: { [key: string]: ValidationField }) => {
    const key = Object.keys(updatedField)[0]
    const newSearch = updatedField[key]
    setSelectedAddress(undefined)

    await onSearchChange(newSearch.value, newSearch.isValid)
  }

  const handleSelectAddress = async (address: AddressLine) => {
    setSelectedAddress(address)
    reportSelectedAutoCompleteAddress(address)
    await onSelectAddress(address)
  }

  const handleSelectCurrentLocation = (address: AddressLine) => {
    reportSelectedCurrentLocation(convertAddressLineToIAddress(address, countryCode || 'AU'))
    handleSelectAddress(address)
    enableCurrentLocation()
  }

  return (
    <div data-testid={testID} className={css.wrapper}>
      <GenericCard
        testID={testID}
        title={title}
        TitleTriggerComponent={
          autoCompleteListMapDeliveryAddress &&
          autoCompleteDeliveryDisplayMapListToggle && (
            <MapListToggle
              testID={testID}
              searchMode={searchMode ?? 'List'}
              onTitleTriggerClick={onTitleTriggerClick}
            />
          )
        }
      >
        {displayCurrentLocation && !input ? (
          <CurrentLocationDisplayField
            location={detectedLocationAddressLine?.address}
            testID={testID}
            onClearInput={disableCurrentLocation}
          />
        ) : (
          <ValidationTextField
            testID={testID}
            fieldName={'addressSearch'}
            placeholder={placeholder}
            hasClearInputButton
            hideEmptyErrorContainer
            onChange={handleChange}
            validationRules={validationRules}
            initialValue={searchAddressInput}
          />
        )}
        <DeliverySearchToolTip />
        <AddressSearchResults
          testID={`${testID}.searchResults`}
          type={DELIVERY_SERVICE_METHOD}
          searchValue={input}
          addresses={predictions}
          isLoading={isLoading}
          itemLoadingKey={selectedAddress?.uid}
          hasError={isError}
          onSelectAddress={handleSelectAddress}
        />
      </GenericCard>

      {!input && !selectedAddress ? (
        <div className={css.storedListWrapper}>
          <AddressSearchStoredList
            testID={`${testID}.storedList`}
            location={detectedLocationAddressLine}
            type={DELIVERY_SERVICE_METHOD}
            onSelectCurrentLocation={handleSelectCurrentLocation}
          />
        </div>
      ) : null}
    </div>
  )
}
