import {
  BottomBar,
  ProductCustomiser,
  ProductDescription,
  ProductEditor,
  ProductEditorContentWrapper,
  ProductImage,
  useProductContext,
} from '@dominos/components'
import {
  createBasketLineSwap,
  getCurrentIngredients,
  useSauceData,
  useToppingData,
} from '@dominos/components/product/functions'
import { useProduct } from '@dominos/hooks-and-hocs'
import React, { useEffect, useState } from 'react'
import css from '../portion-product-card.less'
import { PortionCustomisationEditorProps } from './portion-customisation-editor.interface'
export const PortionCustomisationEditor = ({
  testID,
  onDismiss,
  customisingPortionIndex,
  onCustomisationCompleted,
  t,
}: PortionCustomisationEditorProps) => {
  const { dimensionSetState, portions, handlePortionCustomisations } = useProductContext()
  const [sauceSwap, setSauceSwap] = useState<BasketLineSwap | undefined>()
  const [toppingChanges, setToppingChanges] = useState<BasketLineChange[] | undefined>()
  const portion = portions?.[customisingPortionIndex]

  useEffect(() => {
    setSauceSwap(portion?.sauce)
    setToppingChanges(portion?.toppings)
  }, [customisingPortionIndex])

  const portionData = useProduct(portion?.productCode, undefined, true)
  const sauceIngredients = portionData.getIngredients('Sauce', dimensionSetState?.selectedDimensionSet)
  const sauceRules = portionData.getIngredientTypeRule('Sauce', dimensionSetState?.selectedDimensionSet)
  const defaultSauce = sauceIngredients?.find((ingredient) => ingredient.inRecipe === true)
  const sauceData = useSauceData(sauceIngredients, sauceSwap, sauceRules?.minQuantity === 0)

  const toppingIngredients = portionData.getIngredients('Topping', dimensionSetState?.selectedDimensionSet)
  const toppingRules = portionData.getIngredientTypeRule('Topping', dimensionSetState?.selectedDimensionSet)
  const toppingData = useToppingData(toppingIngredients, toppingChanges, toppingRules)

  const onToppingChange = (changes: BasketLineChange[]) => {
    setToppingChanges(changes)
  }
  const onSauceChange = (sauceCode: string) => {
    setSauceSwap(createBasketLineSwap(sauceCode, defaultSauce?.code))
  }

  const saveCustomisations = () => {
    handlePortionCustomisations(customisingPortionIndex, 'sauce', sauceSwap)
    handlePortionCustomisations(customisingPortionIndex, 'toppings', toppingChanges)
    onCustomisationCompleted()
  }

  return (
    <ProductEditor
      testID={`${testID}.portion-editor`}
      onDismiss={onDismiss}
      showBackButton={false}
      showCloseButton={false}
    >
      <ProductImage testID={testID} uri={portionData.media?.largeImage.uri ?? ''}></ProductImage>
      <ProductEditorContentWrapper testID={`${testID}.portion-editor-content`} className={css.portionEditorContent}>
        <ProductDescription testID={testID} media={portionData.media} t={t}></ProductDescription>
        <ProductCustomiser
          sauceData={sauceData}
          onSauceChange={onSauceChange}
          toppingData={toppingData}
          onToppingChange={onToppingChange}
          calculateCurrentIngredientCount={(toppingChanges: BasketLineChange[]) =>
            getCurrentIngredients(toppingIngredients, toppingChanges).length
          }
        />
      </ProductEditorContentWrapper>
      <BottomBar
        testID={`${testID}.portion-bottom-bar`}
        onComplete={saveCustomisations}
        initialQuantity={1}
        isPortionProduct={true}
        forceDisabled={false}
      />
    </ProductEditor>
  )
}
