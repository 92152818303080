import { useFeaturesV2, useKiosk, useShowAccountDetails } from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation'
import * as Scenes from '@dominos/scenes'
import { RouteComponentProps, Router } from '@reach/router'
import React from 'react'
import { RouterContexts } from './router-contexts'
import { isNativeApp } from '@dominos/business/functions'
import { withNativeAppBasket } from '@dominos/business/functions/native-app/with-native-app-basket'

type AddressSearchResultsSceneProps = React.ComponentProps<typeof Scenes.AddressSearchResultsScene>
type ConfirmPickupSceneProps = React.ComponentProps<typeof Scenes.ConfirmPickupScene>

interface AuthenticationRouteProps extends RouteComponentProps {
  showUniversalAuthentication: boolean
  forgeRockAuthentication: boolean
}

const Home = (_props: RouteComponentProps) => <Scenes.HomeScene />
const KioskWelcome = (_props: RouteComponentProps) => <Scenes.KioskWelcomeScene />
const StoreSearch = (_props: RouteComponentProps) => <Scenes.StoreSearchScene />
const DeliveryList = (_props: RouteComponentProps) => <Scenes.DeliveryListScene />
const AutoCompleteAddress = (_props: RouteComponentProps) => <Scenes.AutoCompleteAddressScene />

const AutoCompleteDelivery = (_props: RouteComponentProps) => <Scenes.AutoCompleteDeliveryScene />

const DeliveryAddressSearchAutocomplete = (_props: RouteComponentProps) => (
  <Scenes.DeliveryAddressSearchAutoCompleteScene />
)

const ConfirmAddress = (_props: RouteComponentProps) => <Scenes.ConfirmAddressScene />
const AutoCompletePickup = (_props: RouteComponentProps) => <Scenes.AutoCompletePickupScene />
const ConfirmPickup = (props: RouteComponentProps<ConfirmPickupSceneProps>) => <Scenes.ConfirmPickupScene {...props} />
const TimedOrder = (_props: RouteComponentProps) => <Scenes.TimedOrderScene />
const Coming = (props: RouteComponentProps) => <Scenes.ComingSoonScene {...props} />

const Menu = (props: RouteComponentProps) =>
  isNativeApp() ? withNativeAppBasket(Scenes.MenuScene)(props) : <Scenes.MenuScene {...props} />
const Product = (props: RouteComponentProps) =>
  isNativeApp() ? withNativeAppBasket(Scenes.ProductScene)(props) : <Scenes.ProductScene {...props} />
const Basket = (props: RouteComponentProps) =>
  isNativeApp() ? (
    withNativeAppBasket(Scenes.BasketScene)({ ...props, excludeNavigation: true })
  ) : (
    <Scenes.BasketScene {...props} />
  )
const DeliveryAddress = (_props: RouteComponentProps) => <Scenes.DeliveryAddressScene />
const AddressSearchResults = (props: RouteComponentProps<AddressSearchResultsSceneProps>) => (
  <Scenes.AddressSearchResultsScene {...props} />
)
const Checkout = (_props: RouteComponentProps) => <Scenes.CheckoutScene />
const NativeAppCheckout = (_props: RouteComponentProps) => <Scenes.NativeAppCheckoutScene />
const Tracker = (_props: RouteComponentProps) => <Scenes.TrackerScene />
const NativeAppTracker = (_props: RouteComponentProps) => <Scenes.NativeAppTrackerScene />
const HardUpsell = (props: RouteComponentProps) => <Scenes.HardUpsellScene {...props} />

const Login = (props: AuthenticationRouteProps) =>
  props.showUniversalAuthentication || props.forgeRockAuthentication ? (
    <Scenes.HomeScene showAuthPopup='login' />
  ) : (
    <Scenes.LoginScene />
  )

const NativeAppLogin = (_props: RouteComponentProps) => <Scenes.NativeAppLoginScene />
const CreateAccount = (props: AuthenticationRouteProps) =>
  props.showUniversalAuthentication || props.forgeRockAuthentication ? (
    <Scenes.HomeScene showAuthPopup='register' />
  ) : (
    <Scenes.CreateAccountScene {...(props.location?.state as Scenes.CreateAccountSceneProps)} />
  )
const NativeCreateAccount = (props: RouteComponentProps) => (
  <Scenes.NativeAppCreateAccountScene {...(props.location?.state as Scenes.CreateAccountSceneProps)} />
)
const MyAccount = (_props: RouteComponentProps) => <Scenes.MyAccountScene />
const NativeAppMyAccount = (_props: RouteComponentProps) => <Scenes.NativeAppMyAccountScene />
const ResetPassword = (_props: RouteComponentProps) => <Scenes.ResetPasswordScene />
const FormResolver = (_props: RouteComponentProps) => <Scenes.FormResolverScene />
const AuthCallback = (_props: RouteComponentProps) => <Scenes.AuthCallbackScene />

export const Routes = () => {
  const isShowAccountDetailsEnabled = useShowAccountDetails()
  const { isKioskOrder } = useKiosk()
  const [
    customerEnabled,
    universalAuthenticationRedirectEnabled,
    autoCompleteDeliveryEnabled,
    autoCompletePickupEnabled,
    forgeRockIdentityProviderEnabled,
    autoCompleteListMapDeliveryAddressEnabled,
    deliveryAddressSearchAutocompleteFeatureEnabled,
  ] = useFeaturesV2(
    'customers',
    'UniversalAuthenticationRedirectHome',
    'AutoCompleteDeliveryAddress',
    'AutoCompletePickupAddress',
    'ForgeRockIdentityProvider',
    'AutoCompleteListMapDeliveryAddress',
    'DeliveryAddressSearch-Feature',
  )

  const renderDeliveryAddressSearchFeature = () => {
    if (deliveryAddressSearchAutocompleteFeatureEnabled)
      return (
        <>
          <DeliveryAddressSearchAutocomplete path={NavigationConstants.deliveryAddressAutoComplete} />
          <ConfirmAddress path={NavigationConstants.confirmAddress} />
        </>
      )

    if (autoCompleteDeliveryEnabled && autoCompleteListMapDeliveryAddressEnabled)
      return (
        <>
          <AutoCompleteDelivery path={NavigationConstants.deliveryAddressAutoComplete} />
          <ConfirmAddress path={NavigationConstants.confirmAddress} />
        </>
      )

    if (autoCompleteDeliveryEnabled)
      return (
        <>
          <AutoCompleteAddress path={NavigationConstants.deliveryAddressAutoComplete} />
          <ConfirmAddress path={NavigationConstants.confirmAddress} />
        </>
      )

    return (
      <>
        <DeliveryAddress path={`${NavigationConstants.deliveryAddress}/*`} />
        <AddressSearchResults path={NavigationConstants.addressSearchResults} />
      </>
    )
  }

  return (
    <Router>
      <RouterContexts path='/'>
        {!isKioskOrder ? (
          <Home path={NavigationConstants.home} default />
        ) : (
          <KioskWelcome path={NavigationConstants.home} default />
        )}
        <StoreSearch path={NavigationConstants.storeSearch} />
        {autoCompletePickupEnabled && (
          <>
            <AutoCompletePickup path={NavigationConstants.pickupAddressAutoComplete} />
            <ConfirmPickup path={NavigationConstants.confirmPickupAddress} />
          </>
        )}

        {renderDeliveryAddressSearchFeature()}
        <DeliveryList path={NavigationConstants.deliveryAddressList} />
        <TimedOrder path={NavigationConstants.timedOrder} />
        <Basket path={NavigationConstants.basket} />
        <Menu path={NavigationConstants.menu} />
        <Menu path={`${NavigationConstants.menu}/:menuCategory`} />
        <Menu path={`${NavigationConstants.menu}/:menuCategory/:variety`} />
        <Product path={`${NavigationConstants.product}/:productCode`} />
        <Coming path={NavigationConstants.comingSoon} />
        <HardUpsell path={NavigationConstants.hardUpsell} />

        <DeliveryAddress path={`${NavigationConstants.deliveryAddress}/*`} />
        <AddressSearchResults path={NavigationConstants.addressSearchResults} />
        <Checkout path={`${NavigationConstants.checkout}/*`} />
        <NativeAppCheckout path={`${NavigationConstants.nativeAppCheckout}/*`} />
        <Tracker path={NavigationConstants.tracker} />
        <NativeAppTracker path={NavigationConstants.nativeAppTracker} />
        {customerEnabled && (
          <>
            <Login
              path={NavigationConstants.login}
              showUniversalAuthentication={universalAuthenticationRedirectEnabled}
              forgeRockAuthentication={forgeRockIdentityProviderEnabled}
            />
            <CreateAccount
              path={NavigationConstants.createAccount}
              showUniversalAuthentication={universalAuthenticationRedirectEnabled}
              forgeRockAuthentication={forgeRockIdentityProviderEnabled}
            />
            {isShowAccountDetailsEnabled && <MyAccount path={`${NavigationConstants.myAccount}/*`} />}
            <NativeAppMyAccount path={`${NavigationConstants.nativeAppMyAccount}/*`} />
          </>
        )}
        <NativeAppLogin path={NavigationConstants.nativeAppLogin} />
        <NativeCreateAccount path={NavigationConstants.nativeAppCreateAccount} />
        <ResetPassword path={`${NavigationConstants.resetPassword}/*/Verification`} />
        <FormResolver path={NavigationConstants.formResolver} />
        <Home path={NavigationConstants.oopsApp} />
        <AuthCallback path={NavigationConstants.authCallback} />
      </RouterContexts>
    </Router>
  )
}
