import { useEffect, useMemo } from 'react'
import { useCurrentStore, useFeaturesV2, useOrderTime, useStoreSelect } from '@dominos/hooks-and-hocs'
import { ServiceMethodSubTypes } from '@dominos/interfaces/checkout'

export const useServiceSubTypes = () => {
  const currentStore = useCurrentStore()
  const { store, getStore } = useStoreSelect()
  const orderTime = useOrderTime()
  const [dropNGoDeliveryEnabled, robotDeliveryEnabled] = useFeaturesV2('DropNGoDelivery', 'EnableRobotDelivery')

  const pickupSubTypes = useMemo(() => (store?.pickupSubTypes ?? []) as ServiceMethodSubTypes[], [store])

  const deliverySubTypes = useMemo(
    () =>
      (store?.deliverySubTypes?.filter(
        (t) =>
          (t === 'DropNGoDelivery' && dropNGoDeliveryEnabled) ||
          (t === 'RobotDelivery' && robotDeliveryEnabled && !orderTime),
      ) ?? []) as ServiceMethodSubTypes[],
    [store],
  )

  useEffect(() => {
    if (currentStore) {
      getStore(currentStore.storeNo, orderTime)
    }
  }, [])

  return {
    pickupSubTypes,
    deliverySubTypes,
  }
}
