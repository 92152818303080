import { useRecentlySearchedAddresses } from '@dominos/hooks-and-hocs'
import { useBatchAddressSearch } from './use-batch-address-search'
import { useFeaturesV2 } from '@dominos/hooks-and-hocs'
import { useMemo } from 'react'

const NUMBER_OF_RECENT_ADDRESSES = 5

export const useRecentAddressesBatch = () => {
  const [deliveryAddressSearch_RecentAddressFetch_Doubled] = useFeaturesV2(
    'DeliveryAddressSearch-RecentAddressFetch-Doubled',
  )

  const recentAddresses = useRecentlySearchedAddresses()

  //! It is possible to have duplicated address in redux store because of the different address spelling (from autocomplete and from store service), for example MY state = Johor vs JHR. So, we need to fetch twice more addresses to ensure we have 5 unique addresses after filtering duplicates. This behaviour should be fixed with implementation of the new address model. Ref ticket: https://dominos.atlassian.net/browse/DAPS-1388
  const multiplier = deliveryAddressSearch_RecentAddressFetch_Doubled ? 2 : 1
  const batchResult = useBatchAddressSearch([...recentAddresses.slice(0, NUMBER_OF_RECENT_ADDRESSES * multiplier)])

  return useMemo(() => [...filterDuplicates(batchResult).slice(0, NUMBER_OF_RECENT_ADDRESSES)], [batchResult])
}

const filterDuplicates = (addresses: StoredCustomerAddress[]) => {
  const uniqueAddresses: StoredCustomerAddress[] = []
  const seen: Set<string> = new Set()

  for (const address of addresses) {
    const rawAddressObject = JSON.stringify(address.customerAddress)
    if (!seen.has(rawAddressObject)) {
      seen.add(rawAddressObject)
      uniqueAddresses.push(address)
    }
  }

  return uniqueAddresses
}
