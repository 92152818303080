import { rootActions } from '@dominos/business'
import { DimensionFilter } from '@dominos/business/functions/menu'
import {
  isPortionMenuItem,
  isProductMenuItem,
  ProductCardSwitcher,
  ProductCardWrapper,
  ProductProvider,
} from '@dominos/components'
import { useBreakpoints, useKiosk, useMenu, useReport } from '@dominos/hooks-and-hocs'
import { getStateFromNavigation, NavigationConstants } from '@dominos/navigation'
import { navigate, RouteComponentProps } from '@reach/router'
import React from 'react'
import { useDispatch } from 'react-redux'
import { ProductSceneProps } from './product-scene.interface'
import { useParams } from '@reach/router'

const TIMEOUT = 500

export const ProductScene = (props: ProductSceneProps & RouteComponentProps) => {
  const { location, onDismiss, path } = props

  const { reportProductAddToCart } = useReport()
  const { isMobile } = useBreakpoints()
  const { isKioskOrder } = useKiosk()
  const { itemsByCode } = useMenu()

  const deepLinkProductCode = useParams().productCode

  const redirectUrl: string | undefined = getStateFromNavigation(location, 'redirectUrl') || props.redirectUrl
  const lineData: BasketLine = getStateFromNavigation(location, 'currentLine') || props.currentLine
  const isEditing = getStateFromNavigation<boolean | undefined>(location, 'isEditing') || props.isEditing
  const voucherNo = getStateFromNavigation<number | undefined>(location, 'voucherNo') || props.voucherNo
  const voucherItemNo = getStateFromNavigation<number | undefined>(location, 'voucherItemNo') || props.voucherItemNo
  const yPosition = getStateFromNavigation<number | undefined>(location, 'yPosition') || 0
  const swapping = getStateFromNavigation<BasketLine>(location, 'swapping') || props.swapping
  const dimensionFilter = getStateFromNavigation<DimensionFilter>(location, 'dimensionFilter') || props.dimensionFilter
  const selectedProduct =
    getStateFromNavigation<MenuItemDependents | undefined>(location, 'currentProduct') || props.currentProduct
  const isDeepLink = !selectedProduct && deepLinkProductCode
  const product = selectedProduct || (deepLinkProductCode && itemsByCode?.[deepLinkProductCode])
  const currentProduct: ProductMenuItem | PortionMenuItem =
    product && (isProductMenuItem(product) || isPortionMenuItem(product)) && product

  const isMobileLayout = isMobile || isKioskOrder

  const dispatch = useDispatch()
  const addToBasket = (item: BasketLine) => {
    if (swapping) {
      dispatch(
        rootActions.replaceBasketLine({
          remove: swapping,
          add: item,
          voucherNo,
          voucherItemNo,
        }),
      )
    } else if (lineData) {
      dispatch(
        rootActions.replaceBasketLine({
          remove: lineData,
          add: item,
          voucherNo,
          voucherItemNo,
        }),
      )
    } else {
      dispatch(rootActions.addLinesToBasket({ add: [item], voucherNo, voucherItemNo }))
    }
    reportProductAddToCart([item])
  }

  if (!currentProduct || (!isMobileLayout && path === '/product/:productCode')) {
    navigate(NavigationConstants.menu)
  }

  const handleProductSceneDismissal = () => onDismiss && onDismiss()

  const handleDismiss = (addedToBasket?: boolean) => {
    if (isDeepLink) {
      navigate(NavigationConstants.menu)
    } else if (isMobileLayout) {
      if (addedToBasket) {
        !!redirectUrl ? navigate(redirectUrl) : navigate(-1)
      } else {
        navigate(-1).then(() => {
          dispatch(rootActions.viewedProductOnMobile(true))
          setTimeout(() => {
            window.scrollTo(0, yPosition || 0)
          }, TIMEOUT)
        })
      }
    } else {
      handleProductSceneDismissal()
    }
    if (!isMobileLayout && addedToBasket && !!redirectUrl) {
      navigate(redirectUrl)
    }
  }

  return (
    <ProductProvider product={currentProduct} basketLine={lineData} dimensionFilter={dimensionFilter}>
      <ProductCardWrapper testID='product-card-wrapper' onDismiss={handleProductSceneDismissal}>
        <ProductCardSwitcher
          currentProduct={currentProduct}
          lineData={lineData}
          isEditing={isEditing}
          onDismiss={handleDismiss}
          addToBasket={addToBasket}
        />
      </ProductCardWrapper>
    </ProductProvider>
  )
}
