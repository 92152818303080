import { useDominosTheme } from '@dominos/hooks-and-hocs'
import React from 'react'
import { navigate, useLocation } from '@reach/router'
import { NavigationConstants } from '@dominos/navigation'
import { useAuthenticationPopup, useFeaturesV2, useForgeRock, useReport, useTooltip } from '@dominos/hooks-and-hocs'
import { useTranslation } from 'react-i18next'
import { AuthenticationButton } from './authentication-button'
import styles from './authentication-buttons-container.less'
import { ToolTip } from './tool-tip'
import { ActionButton } from '@dominos/components'
import { useIsAuthenticated } from '@dominos/components/login-b2b/AuthProviderB2B'
import { B2BAuth } from '@dominos/components/login-b2b/B2BAuth'

export const MAX_TIMES_TO_SHOW = 2
export const AuthenticationButtonsContainer = (props: BaseProps) => {
  const { pathname } = useLocation()
  const { t } = useTranslation('login')
  const { reportInteraction } = useReport()
  const { showPopup } = useAuthenticationPopup()
  const [universalAuthenticationEnabled, signupButtonToolTipEnabled, forgeRockIdentityProviderEnabled] = useFeaturesV2(
    'UniversalAuthentication',
    'SignUpToolTip',
    'ForgeRockIdentityProvider',
  )
  const theme = useDominosTheme()
  const isAuthenticated = useIsAuthenticated()

  const { shouldShowTooltip, closeTooltip } = useTooltip({
    maxTooltipShowCount: MAX_TIMES_TO_SHOW,
    currentPath: pathname,
    skip: !signupButtonToolTipEnabled,
  })

  const { loginForgeRock, registerForgeRock } = useForgeRock()

  const handleClick = async (clickEventType: 'login' | 'register') => {
    if (forgeRockIdentityProviderEnabled) {
      if (clickEventType === 'login') {
        await loginForgeRock()
      } else {
        await registerForgeRock()
      }
    } else {
      const interactionEvent = clickEventType[0].toUpperCase() + clickEventType.slice(1)
      reportInteraction(interactionEvent)
      const navigationRoute = clickEventType === 'login' ? NavigationConstants.login : NavigationConstants.createAccount
      if (universalAuthenticationEnabled) {
        showPopup(clickEventType)
      } else {
        navigate(navigationRoute, { state: { redirectTo: pathname } }) // origin of redirectTo
      }
      closeTooltip(true)
    }
  }

  if (isAuthenticated) {
    return (
      <ActionButton
        onPress={() => B2BAuth.logout()}
        containerStyle={{
          backgroundColor: theme.colours.defaultBackground,
        }}
        textStyle={{ color: '#3478F6' }}
        testID={`${props.testID}.signout.button`}
        textFontWeight='semibold'
        text={t('Log out', { defaultValue: 'Log out' })}
      />
    )
  }

  return (
    <div data-testid={props.testID} className={styles.container}>
      <AuthenticationButton
        testID={`${props.testID}.signin.button`}
        name='login'
        textStyle={{ color: '#3478F6' }}
        onAuthButtonClick={handleClick}
        containerStyle={{
          backgroundColor: theme.colours.defaultBackground,
        }}
        textFontWeight='semibold'
        text={t('SignIn', { defaultValue: 'Sign in' })}
      />
      <AuthenticationButton
        testID={`${props.testID}.signup.button`}
        containerStyle={{
          backgroundColor: theme.colours.deemphasisedBlue,
        }}
        textStyle={{ color: '#3478F6' }}
        text={t('SignUp', { defaultValue: 'Sign Up' })}
        name='register'
        textFontWeight='semibold'
        onAuthButtonClick={handleClick}
      >
        {shouldShowTooltip && signupButtonToolTipEnabled && (
          <div className={styles.tooltipContainer}>
            <ToolTip
              testID={`${props.testID}.tooltip`}
              title={t('SignUpButtonToolTipTitle', { defaultValue: 'Create an Account' })}
              subTitle={t('SignUpButtonToolTipSubTitle', {
                defaultValue: 'Tap here to create an account for faster ordering and easier checkout.',
              })}
              onClose={closeTooltip}
            />
          </div>
        )}
      </AuthenticationButton>
    </div>
  )
}
