import { PortionCustomisationEditor, PortionProductEditor } from '@dominos/components/product/portion-product-card'
import React, { useState } from 'react'
import { PortionProductCardProps } from './portion-product-card.interface'

export const PortionProductCard = ({ testID, onDismiss, addToBasket, isEditing, t }: PortionProductCardProps) => {
  const [customisingPortionIndex, setCustomisingPortionIndex] = useState<number | undefined>()

  const onPortionCustomise = (portionIndex: number) => {
    setCustomisingPortionIndex(portionIndex)
  }

  const onCustomisationCompleted = () => {
    setCustomisingPortionIndex(undefined)
  }

  return customisingPortionIndex !== undefined ? (
    <PortionCustomisationEditor
      testID={testID}
      t={t}
      onDismiss={onDismiss}
      customisingPortionIndex={customisingPortionIndex}
      onCustomisationCompleted={onCustomisationCompleted}
    ></PortionCustomisationEditor>
  ) : (
    <PortionProductEditor
      addToBasket={addToBasket}
      onDismiss={onDismiss}
      isEditing={isEditing}
      t={t}
      testID={testID}
      onPortionCustomise={onPortionCustomise}
    ></PortionProductEditor>
  )
}
