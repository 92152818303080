import { BaseProductEditor, ProductCustomiser, useProductContext } from '@dominos/components'
import { createBasketLineSwap, useBaseData, useSauceData, useSizeData } from '@dominos/components/product/functions'
import React from 'react'
import { RecipeProductCardProps } from './recipe-product-card.interface'

export const RecipeProductCard = ({ testID, onDismiss, t }: RecipeProductCardProps) => {
  const { dimensionSetState, productData, saveBasketLineDataChanges, currentBasketLineData } = useProductContext()

  const sizeData = useSizeData(productData, dimensionSetState)
  const baseData = useBaseData(productData, dimensionSetState)

  const sauceIngredients = productData?.getIngredients('Sauce', dimensionSetState?.selectedDimensionSet) || []
  const sauceRules = productData?.getIngredientTypeRule('Sauce', dimensionSetState?.selectedDimensionSet)
  const defaultSauce = sauceIngredients?.find((ingredient) => ingredient.inRecipe === true)
  const sauceData = useSauceData(sauceIngredients, currentBasketLineData?.sauce, sauceRules?.minQuantity === 0)

  const onBaseChange = (baseCode: string) => {
    dimensionSetState?.handleDimensionChange('Base', baseCode)
  }

  const onSizeChange = (sizeCode: string) => {
    dimensionSetState?.handleDimensionChange('Size', sizeCode, true)
  }
  const onSauceChange = (sauceCode: string) => {
    saveBasketLineDataChanges('sauce', createBasketLineSwap(sauceCode, defaultSauce?.code))
  }

  return (
    <BaseProductEditor testID={`${testID}.base-product-editor`} onDismiss={onDismiss} t={t}>
      <ProductCustomiser
        sizeData={sizeData}
        onSizeChange={onSizeChange}
        baseData={baseData}
        onBaseChange={onBaseChange}
        sauceData={sauceData}
        onSauceChange={onSauceChange}
      />
    </BaseProductEditor>
  )
}
