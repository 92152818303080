import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { notifyNativeApp } from '@dominos/business/functions/native-app'
import { CollapsableTitledCard, Spinner } from '@dominos/components'
import { Breadcrumb, getBreadcrumbTitlesMyOrder } from '@dominos/components/breadcrumb'
import {
  useCurrentOrderDetails,
  useCustomer,
  useFeaturesV2,
  useLoyaltyMarketing,
  useRememberedCustomerDetails,
  useRememberMyDetails,
} from '@dominos/hooks-and-hocs'

import { DeliveryDetailsForm } from './delivery-details-form'
import { detailsFieldNames } from './details-container-config'
import { DetailsContainerProps, FormValue } from './details-container.interface'
import { mapCustomerOrderDetails } from './helpers'
import { Loyalty } from './loyalty'
import { MyDetailsForm } from './my-details-form'
import { PickUpDetailsForm } from './pick-up-details-form'
import { VipOffers } from './vip-offers'
import styles from './details-container.less'

export interface CustomerOrderDetails {
  name: string
  alternateName: string
  emailAddress: string
  mobileNumber: string
  loyaltyEnrolled: { isEnrolled: boolean }
}

const DetailsContainer = ({ testID, onComplete }: DetailsContainerProps) => {
  const { t } = useTranslation('checkout')
  const { isLoggedIn, isLoyaltyEnrolled } = useCustomer()
  const { basketHeaderData: currentOrderData } = useCurrentOrderDetails()
  const { customerDetails, saveDetails } = useRememberedCustomerDetails()
  const [vipOffersEnabled] = useFeaturesV2('VipOffers')
  const { enrollCustomerInLoyaltyMarketing } = useLoyaltyMarketing()
  const [myDetails, setMyDetails] = useState<{ [key: string]: FormValue }>({})
  const [isMyDetailsValid, setIsMyDetailsValid] = useState<boolean>(false)
  const [loyaltyEnrolled, setLoyaltyEnrolled] = useState<boolean>((isLoggedIn && isLoyaltyEnrolled) ?? false)
  const [receiveUpdatesAndOffers, setReceiveUpdatesAndOffers] = useState<boolean>(false)
  const { rememberMyDetailsState, setRememberMyDetailsState, saveRememberMyDetailsCheck } = useRememberMyDetails()
  const [eClub, setEClub] = useState<{ [key: string]: boolean }>({})

  const basketData = useSelector((state: RootReducer) => state.basketReducer.basket)

  const [customerOrderDetails, setCustomerOrderDetails] = useState<CustomerOrderDetails>(() =>
    mapCustomerOrderDetails(myDetails, (isLoggedIn && isLoyaltyEnrolled) ?? false),
  )

  useEffect(() => {
    const customerOrderDetails = mapCustomerOrderDetails(myDetails, loyaltyEnrolled)
    setCustomerOrderDetails(customerOrderDetails)
  }, [myDetails, loyaltyEnrolled])

  useEffect(() => {
    notifyNativeApp('details')
  }, [])

  if (!currentOrderData) {
    return <Spinner testID='details-container-spinner' />
  }

  const handleMyDetailsChange = (field: string | null, value: FormValue) => {
    if (field === detailsFieldNames.rememberMyDetails) {
      setRememberMyDetailsState(!!value)
    }
    setMyDetails((prev) => ({ ...prev, [field!]: value }))
  }

  const handleEClubChange = (field: string | null, value: boolean) => {
    setEClub((prev) => ({ ...prev, [field!]: value }))
  }

  const handleComplete = () => {
    saveRememberMyDetailsCheck(rememberMyDetailsState)
    saveDetails(!isLoggedIn && rememberMyDetailsState && customerOrderDetails)

    if (receiveUpdatesAndOffers) {
      enrollCustomerInLoyaltyMarketing()
    }

    onComplete()
  }

  return (
    <div>
      <Breadcrumb
        testID='checkout.details.breadcrumb'
        entities={getBreadcrumbTitlesMyOrder(basketData.total, basketData.lines.length)}
        flowDepth={1}
        textNamespace={['checkout', 'basket']}
      />
      <Loyalty
        enrolled={loyaltyEnrolled}
        receiveUpdatesAndOffers={receiveUpdatesAndOffers}
        onCheck={setLoyaltyEnrolled}
        onCheckReceiveUpdates={setReceiveUpdatesAndOffers}
      />
      <CollapsableTitledCard
        primaryTitle={t('My Details')}
        startCollapsed={false}
        noPadding={true}
        testID={'checkout-container-card'}
      >
        <div className={styles.titledCardContent}>
          <MyDetailsForm
            onFormValidationChange={setIsMyDetailsValid}
            initialDetails={customerDetails}
            initialRememberDetails={rememberMyDetailsState}
            onChange={handleMyDetailsChange}
          />
        </div>
        {vipOffersEnabled && <VipOffers onChange={handleEClubChange} />}
      </CollapsableTitledCard>

      {currentOrderData.serviceMethod === 'Delivery' ? (
        <DeliveryDetailsForm
          customerOrderDetails={customerOrderDetails}
          isMyDetailsValid={isMyDetailsValid}
          eClub={eClub}
          testID={testID}
          onComplete={handleComplete}
        />
      ) : (
        <PickUpDetailsForm
          customerOrderDetails={customerOrderDetails}
          isMyDetailsValid={isMyDetailsValid}
          eClub={eClub}
          testID={testID}
          onComplete={handleComplete}
        />
      )}
    </div>
  )
}

export { DetailsContainer }
