import { useFeaturesV2 } from '@dominos/hooks-and-hocs'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ActionButton, GenericSelect } from '@dominos/components'
import { svgIcons } from '@dominos/res/images/icons'
import { getProductData, productToSelectItem } from '../functions'

import css from './half-card.less'

const SELECT_HEIGHT = 32

export const HalfCard = ({ products = [], onProductChange, item, side, onEditPress }: HalfCardProps) => {
  const { t } = useTranslation('half-n-half')
  const [restrictedHalfHalfEnabled] = useFeaturesV2('RestrictedHalfHalf')
  const CONTENT = {
    SELECT_PIZZA: t('Select Pizza'),
    CUSTOMISE: t('Customise'),
  }
  const testPrefix = `half.card-${side}`
  const selectOptions = useMemo(() => products.map(productToSelectItem), [products])

  const onSelectChange = (value: string) => onProductChange && onProductChange(products.find((p) => p.code === value))

  return (
    <div
      className={css.cardWrapper}
      style={{ backgroundColor: item ? '#ffffff' : '#dddddd' }}
      data-testid={`${testPrefix}.wrapper`}
    >
      {item ? (
        <HalfProduct side={side} item={item} testID={`${testPrefix}.product`} />
      ) : (
        <HalfEmptyView side={side} testID={`${testPrefix}.empty`} />
      )}
      <div className={css.selectListWrapper}>
        <GenericSelect
          testID={`${testPrefix}.pizza-list`}
          options={selectOptions}
          placeholder={CONTENT.SELECT_PIZZA}
          selectedValue={item && item.code}
          onChange={onSelectChange}
          accessibilityLabel={CONTENT.SELECT_PIZZA}
          height={SELECT_HEIGHT}
        />
      </div>
      {item && !restrictedHalfHalfEnabled && (
        <div className={css.actionButtonWrapper}>
          <HalfActionButton text={CONTENT.CUSTOMISE} onPress={onEditPress} testID={`${testPrefix}.action`} />
        </div>
      )}
    </div>
  )
}

export const HalfEmptyView = ({ side, testID }: HalfEmptyViewProps) => {
  const Icon = svgIcons[side === 'left' ? 'halfEmptyLeft' : 'halfEmptyRight']

  return (
    <div className={css.iconWrapper} data-testid={testID}>
      <Icon width={'80%'} />
    </div>
  )
}

export const HalfProduct = ({ item, testID }: HalfProductProps) => {
  const data = getProductData(item)

  return <img src={data.imageUrl} className={css.image} data-testid={`${testID}.image`} />
}

export const HalfActionButton = ({ testID, text, onPress }: HalfActionButtonProps) => (
  <ActionButton
    containerStyle={{ justifyContent: 'center', minHeight: 32, width: '100%' }}
    textStyle={{ fontSize: 14 }}
    testID={`${testID}.action-button`}
    text={text}
    onPress={onPress}
    textFontWeight='semibold'
  />
)
