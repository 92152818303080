import { FeatureFlagType } from '@dominos/hooks-and-hocs/features'

export const createConfig = (
  store: RootReducer,
  features: FeatureFlagType,
  variables?:
    | MutationWrapper<ValidateBasketInput>
    | MutationWrapper<Bff.Fos.FosEventRequest>
    | { storeNo: number; serviceMethod: BffContext.ServiceMethods; requestTime?: string }
    | { storeNo: number }
    | MutationWrapper<Bff.Stores.StoresAlternativeDistanceRequest>,
): GraphConfig | GraphSetup | null => {
  if (store.applicationReducer.countryCode) {
    return {
      url: store.applicationReducer.graphUrl,
      variables: {
        ...variables,
        culture: `${store.applicationReducer.language}-${store.applicationReducer.countryCode}`,
      },
      language: store.applicationReducer.language,
      countryCode: store.applicationReducer.countryCode,
      application: store.applicationReducer.application,
      applicationVersion: store.applicationReducer.applicationVersion,
      version: store.applicationReducer.version,
      sessionID: store.sessionReducer.sessionID,
      environment: features?.['release-environment'] ?? '',
    }
  }

  return null
}
