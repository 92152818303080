import { useProductsBatched } from '@dominos/hooks-and-hocs'
import { flattenIngredientsForDimensionSet } from '@dominos/hooks-and-hocs/menu/functions'
import { useEffect } from 'react'

type IngredientTypeCode = Bff.Common.IngredientTypeCode
type DimensionSet = Bff.Dimensions.DimensionSet

const useProductIngredientsDictionary = (productCodes: string[]) => {
  const { fetchProductsByCodes, products: fetchedProducts, loading } = useProductsBatched()

  useEffect(() => {
    fetchProductsByCodes(productCodes)
  }, [productCodes])

  return {
    get loading() {
      return loading
    },
    getIngredients: (
      ingredientType: IngredientTypeCode,
      dimensionSet?: DimensionSet,
    ): Map<string, IngredientModel[]> => {
      const transformedIngredients = new Map<string, IngredientModel[]>()
      fetchedProducts.forEach((product) => {
        transformedIngredients.set(
          product.code,
          flattenIngredientsForDimensionSet(ingredientType, dimensionSet, product),
        )
      })

      return transformedIngredients
    },
  }
}

export { useProductIngredientsDictionary }
