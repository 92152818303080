import { CurrentOrderDetailsReducerProps } from '@dominos/business/reducers/current-order-details.reducer'
import { SwapType } from '@dominos/interfaces'
import { AnalyticSender, FeatureEventSender, UpsellFeatureType } from './analytics'
import { SecurityEventNames, SecuritySender, toProducts } from './security'
import { applicationName } from '@dominos/business/functions'

declare type ReportConvertibleProduct =
  | BasketLine[]
  | Bff.Orders.OrderDetails.Basket.ProductLine[]
  | ProductMenuItem[]
  | MenuItemDependents[]
  | BasketLineUnionDependancy[]

declare type CardType = 'Visa' | 'Mastercard' | 'AmericanExpress'

const reportProductAddToCart =
  (
    sendSecurity: SecuritySender,
    sendAnalytics: AnalyticSender,
    currentOrder: CurrentOrderDetailsReducerProps | undefined,
  ) =>
  (lines: ReportConvertibleProduct, couponCode?: string) => {
    reportProductAction(sendSecurity, currentOrder, 'Flow.Basket.ProductAddedToBasket', lines, couponCode)

    sendAnalytics('add_to_cart', {
      items: toProducts(lines, couponCode),
    })
  }

const reportProductRemoveFromCart =
  (
    sendSecurity: SecuritySender,
    sendAnalytics: AnalyticSender,
    currentOrder: CurrentOrderDetailsReducerProps | undefined,
  ) =>
  (lines: ReportConvertibleProduct, couponCode?: string) => {
    reportProductAction(sendSecurity, currentOrder, 'Flow.Basket.ProductRemovedFromBasket', lines, couponCode)

    sendAnalytics('remove_from_cart', {
      items: toProducts(lines, couponCode),
    })
  }

const reportProductReplaceInCart =
  (
    sendSecurity: SecuritySender,
    sendAnalytics: AnalyticSender,
    currentOrder: CurrentOrderDetailsReducerProps | undefined,
  ) =>
  (add: ReportConvertibleProduct, remove: ReportConvertibleProduct, couponCode?: string, swapType?: SwapType) => {
    reportProductAction(sendSecurity, currentOrder, 'Flow.Basket.ProductReplacedInBasket', add, couponCode, remove)

    sendAnalytics('remove_from_cart', {
      items: toProducts(remove, couponCode, swapType),
    })

    sendAnalytics('add_to_cart', {
      items: toProducts(add, couponCode, swapType),
    })
  }

const reportUpsellProductAddToCart =
  (sendAnalytics: AnalyticSender) => (lines: ReportConvertibleProduct, upsellFeature: UpsellFeatureType) => {
    sendAnalytics('add_to_cart', {
      items: toProducts(lines),
      upsell_feature: upsellFeature,
    })
  }

const reportProductAction = (
  sendSecurity: SecuritySender,
  currentOrder: CurrentOrderDetailsReducerProps | undefined,
  name: SecurityEventNames,
  lines: ReportConvertibleProduct,
  couponCode?: string,
  replaced?: ReportConvertibleProduct,
) => {
  const data: IBasketEventParams = {
    'Order ID': currentOrder?.orderId,
    Items: toProducts(lines, couponCode),
  }
  if (replaced) {
    data.Replaced = toProducts(replaced, couponCode)
  }

  sendSecurity(name, data)
}

const reportHardUpsellProductAddedToCart =
  (sendFeatureEvent: FeatureEventSender, sendAnalytics: AnalyticSender) => (lines: ReportConvertibleProduct) => {
    sendFeatureEvent('add_to_basket_hardupsell')
    sendAnalytics('side_added_from_hardupsell', {
      event: 'side_added_from_hardupsell',
      items: toProducts(lines),
      platform: applicationName(),
    })
  }
const reportHardUpsellSkip = (sendAnalytics: AnalyticSender) => () => {
  sendAnalytics('hardupsell_skip', {
    event: 'hardupsell_skip',
    platform: applicationName(),
  })
}

const reportHardUpsellInitialised = (sendAnalytics: AnalyticSender) => () => {
  sendAnalytics('hardupsell_initialised', {
    event: 'hardupsell_initialised',
    platform: applicationName(),
  })
}

const reportProductUpsellEnabled = (sendAnalytics: AnalyticSender) => () => {
  sendAnalytics('premium_upsell_enabled')
}

const reportProductUpsellAdded = (sendAnalytics: AnalyticSender) => () => {
  sendAnalytics('premium_upsell_added')
}

const reportProductUpsellRemoved = (sendAnalytics: AnalyticSender) => () => {
  sendAnalytics('premium_upsell_removed')
}

const reportPortionSwap =
  (sendAnalytics: AnalyticSender) =>
  (
    productCode: string,
    portionIndex: number,
    swappedInPortionProductCode: string | undefined,
    swappedOutPortionProductCode: string | undefined,
  ) => {
    sendAnalytics('portion_swap', {
      parent_item_id: productCode,
      swap_position: portionIndex,
      swapped_in_item_id: swappedInPortionProductCode,
      swapped_out_item_id: swappedOutPortionProductCode,
    })
  }

const reportCreateHalfHalf = (sendAnalytics: AnalyticSender) => (fromItemId: string, toItemId: string) => {
  sendAnalytics('create_halfhalf', {
    from_item_id: fromItemId,
    to_item_id: toItemId,
  })
}

export const reportProduct = (
  sendSecurity: SecuritySender,
  sendAnalytics: AnalyticSender,
  sendFeatureEvent: FeatureEventSender,
  currentOrder?: CurrentOrderDetailsReducerProps,
) => ({
  reportPortionSwap: reportPortionSwap(sendAnalytics),
  reportProductAddToCart: reportProductAddToCart(sendSecurity, sendAnalytics, currentOrder),
  reportProductRemoveFromCart: reportProductRemoveFromCart(sendSecurity, sendAnalytics, currentOrder),
  reportProductReplaceInCart: reportProductReplaceInCart(sendSecurity, sendAnalytics, currentOrder),
  reportUpsellProductAddToCart: reportUpsellProductAddToCart(sendAnalytics),
  reportHardUpsellProductAddedToCart: reportHardUpsellProductAddedToCart(sendFeatureEvent, sendAnalytics),
  reportHardUpsellSkip: reportHardUpsellSkip(sendAnalytics),
  reportHardUpsellInitialised: reportHardUpsellInitialised(sendAnalytics),
  reportProductUpsellEnabled: reportProductUpsellEnabled(sendAnalytics),
  reportProductUpsellAdded: reportProductUpsellAdded(sendAnalytics),
  reportProductUpsellRemoved: reportProductUpsellRemoved(sendAnalytics),
  reportCreateHalfHalf: reportCreateHalfHalf(sendAnalytics),
})
