import { useCallback, useEffect, useMemo, useState } from 'react'
import { useCountryCode, useFeaturesV2, useLanguages, useReport } from '@dominos/hooks-and-hocs'
import { getCountryConfig } from '@dominos/business/functions/common/get-config'
import { ERROR_NO_CLOSEST_GEO_CODED_LOCATION } from '@dominos/components'

import { ProviderAutoComplete } from '../providers.interface'
import { mapGooglePredictionsToAddressLines } from './map-google-predictions-to-address-lines'
import { mapGoogleGeoToDeliveryAddressRequest } from './map-google-geo-to-delivery-address-request'
import { getConfigTypes } from './get-config-types'
import { validateGoogleAddressComponent } from './validate-google-address-component'
import { useTranslation } from 'react-i18next'
import { getLoaderInstance } from 'olo-feature-address'

const {
  GOOGLE_MAPS_API_KEY,
  GOOGLE_PLACES_DELIVERY_TYPES,
  GOOGLE_PLACES_PICKUP_TYPES,
  DELIVERY_ADDRESS_SEARCH_MATCH_RADIUS,
} = getCountryConfig()

const loader = getLoaderInstance(GOOGLE_MAPS_API_KEY)

const getDeliveryAddressSearchMatchRadius: number = parseInt(DELIVERY_ADDRESS_SEARCH_MATCH_RADIUS)

// eslint-disable-next-line max-lines-per-function
export function GoogleAutoComplete(type: BffContext.ServiceMethods): ProviderAutoComplete {
  const country = useCountryCode()
  const { language } = useLanguages()
  const { reportAutoCompleteUsage } = useReport()
  const { t } = useTranslation('delivery-address')
  const [autoCompleteDeliveryAddressSkipGoogleValidation] = useFeaturesV2(
    'AutoCompleteDeliveryAddressSkipGoogleValidation',
  )

  const [sessionToken, setSessionToken] = useState<google.maps.places.AutocompleteSessionToken>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [predictions, setPredictions] = useState<AddressLine[]>()
  const [address, setAddress] = useState<DeliveryAddressRequest>()
  const [searchValue, setSearchValue] = useState<string>()
  const [error, setError] = useState<ValidateAddressComponent>()
  const googlePlacesTypes = useMemo(() => {
    const configTypes =
      type === 'Pickup' ? getConfigTypes(GOOGLE_PLACES_PICKUP_TYPES) : getConfigTypes(GOOGLE_PLACES_DELIVERY_TYPES)

    return getGoogleTypes(configTypes)
  }, [type])

  useEffect(() => {
    setIsLoading(true)
    loader.load().then(async () => {
      const { AutocompleteSessionToken } = (await google.maps.importLibrary('places')) as google.maps.PlacesLibrary

      /**
       * Creates a new token whenever the page in use is initialised.
       * Includes first load, reload, and navigating back to the page.
       *
       * In practice, this means a new 'session' (with new session costs)
       * will be created whenever a user does one of the above actions,
       * within a page containing the autocomplete component.
       *
       * **TLDR: The session will only be maintained for repeated searches,
       * once a user selects a search result then the session is finished.**
       */
      const sessionToken = new AutocompleteSessionToken()

      setSessionToken(sessionToken)
      setIsLoading(false)
      setError(undefined)
    })
  }, [])

  const reset = (input: string | undefined) => {
    setPredictions(undefined)
    setAddress(undefined)
    setSearchValue(input)
    setIsLoading(false)
    setError(undefined)
  }

  const resetError = () => {
    setError(undefined)
  }

  const resetPredictions = () => {
    setPredictions(undefined)
  }

  const getPredictions = useCallback(async (input: string) => {
    setIsLoading(true)

    const { AutocompleteService } = (await google.maps.importLibrary('places')) as google.maps.PlacesLibrary
    const autocompleteService = new AutocompleteService()

    const placePredictions = await autocompleteService.getPlacePredictions({
      input,
      componentRestrictions: { country: country ? country.toLowerCase() : null },
      // will return NO results if this is incorrectly setup
      types: googlePlacesTypes,
      sessionToken,
      language,
    })

    const addresses = mapGooglePredictionsToAddressLines(placePredictions, language)

    setPredictions(addresses)
    setSearchValue(input)
    setIsLoading(false)
  }, [])

  const reverseGeoCodeAddress = async (address: AddressLine) => {
    if (!address.geo) return undefined

    const { Geocoder } = (await google.maps.importLibrary('geocoding')) as google.maps.GeocodingLibrary
    const { geocode } = new Geocoder()

    if (address.geo.id) {
      return await geocode({
        placeId: address.geo.id,
        language,
      })
    }

    if (address.geo.latitude && address.geo.longitude) {
      return await geocode({
        location: { lat: address.geo.latitude, lng: address.geo.longitude },
        language,
      })
    }
  }

  const getAddress = async (address: AddressLine, shouldValidateAddress: boolean = true) => {
    setIsLoading(true)

    const geoCodeAddressResponse = await reverseGeoCodeAddress(address)

    if (!geoCodeAddressResponse) {
      setIsLoading(false)

      return
    }

    const deliveryAddressRequest = mapGoogleGeoToDeliveryAddressRequest(
      geoCodeAddressResponse,
      address,
      country,
      getDeliveryAddressSearchMatchRadius,
    )
    if (!deliveryAddressRequest) {
      setError({ isValid: false, reason: ERROR_NO_CLOSEST_GEO_CODED_LOCATION })
      setIsLoading(false)

      return
    }

    if (!shouldValidateAddress) {
      reportAutoCompleteUsage('address found')
      setAddress(deliveryAddressRequest)
      setIsLoading(false)

      return deliveryAddressRequest
    }

    if (autoCompleteDeliveryAddressSkipGoogleValidation) {
      reportAutoCompleteUsage('address found')
      setAddress(deliveryAddressRequest)
    } else {
      const isValid = validateDeliveryAddressRequest(deliveryAddressRequest)
      if (isValid) {
        reportAutoCompleteUsage('address found')
        setAddress(deliveryAddressRequest)

        return deliveryAddressRequest
      }
    }

    setIsLoading(false)
  }

  const validateDeliveryAddressRequest = (addressRequest: DeliveryAddressRequest | undefined) => {
    const validateGoogleAddress = validateGoogleAddressComponent(t, addressRequest?.addressComponents, country)

    if (!validateGoogleAddress.isValid) {
      setError(validateGoogleAddress)
    }

    return validateGoogleAddress.isValid
  }

  return {
    getPredictions,
    getAddress,
    reset,
    isLoading,
    predictions,
    address,
    input: searchValue,
    error,
    resetError,
    resetPredictions,
    validateDeliveryAddressRequest,
  }
}

const getGoogleTypes = (configGoogleTypes: string[] | undefined) =>
  !configGoogleTypes ? undefined : removeEmptyArrayElements(configGoogleTypes)

const removeEmptyArrayElements = (array: string[]) => array.filter((item) => item)
