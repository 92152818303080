import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLazyQuery } from '@apollo/client'
import { rootActions } from '@dominos/business'
import { featuresQuery } from '@dominos/business/queries/features'
import { useLaunchDarklyContext } from '@dominos/hooks-and-hocs'
import { defaultFeatures, FeatureContext, FeatureToggleProps } from './feature-context'

interface FeatureProviderProps {
  children: ReactNode
  timeoutTime?: number
}

export const FeatureProvider = (props: FeatureProviderProps) => {
  const { launchDarklyID } = useLaunchDarklyContext()
  const dispatch = useDispatch()
  const countryCode = useSelector((state: RootReducer) => state.applicationReducer.countryCode || 'AU')
  const [loading, setLoading] = useState(true)

  const initFeatures = useSelector((state: RootReducer) => state.featureReducer.features || defaultFeatures)

  const [sendRequest, { data: features, loading: featuresLoading }] = useLazyQuery(featuresQuery, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      dispatch(rootActions.setFeatureToggles(data.featureToggles))
      setLoading(false)
    },
  })

  const updateFeatures = useCallback(() => {
    sendRequest({ variables: { userId: launchDarklyID } })
  }, [launchDarklyID, countryCode])

  useEffect(() => {
    if (props.timeoutTime) {
      const time = setTimeout(() => {
        updateFeatures()
      }, props.timeoutTime)

      return () => clearTimeout(time)
    }
    updateFeatures()

    return () => {}
  }, [launchDarklyID, countryCode])

  const featuresModel: FeatureToggleProps = useMemo(
    () => ({
      features: features?.featureToggles ?? initFeatures,
      loading: loading || featuresLoading,
      update: updateFeatures,
    }),
    [features, loading, launchDarklyID],
  )

  return <FeatureContext.Provider value={featuresModel}>{props.children}</FeatureContext.Provider>
}
