import { DimensionFilter } from '@dominos/business/functions/menu'
import { isDimensionalPortionProduct, isPortionMenuItem, isProductMenuItem } from '@dominos/components'
import { ProductData, useDevToggles, useFeaturesV2, useMenu, useProducts } from '@dominos/hooks-and-hocs'
import {
  appendMediaToPortionPossibleProducts,
  calculatePriceDifferenceForDimensionChange,
  createDimensionSet,
  filterDimensionSets,
  findEntityByDimensionSet,
  flattenIngredientsForDimensionSet,
  flattenIngredientTypeRulesForDimensionSet,
  getDefaultDimensionSetFromProduct,
  getDimensionSetTypeFromProduct,
  getImageMedia,
  getTypeFromProduct,
  isDimensionSetValid,
} from '@dominos/hooks-and-hocs/menu/functions'
import { useEffect } from 'react'

type DimensionSet = Bff.Dimensions.DimensionSet
type SizeDimensionSet = Bff.Dimensions.SizeDimensionSet
type SizeBaseDimensionSet = Bff.Dimensions.SizeBaseDimensionSet
type NonDimensionalSet = Bff.Dimensions.NonDimensionalSet
type DimensionTypeCode = Bff.Common.DimensionTypeCode
type CurrencyPrice = Bff.Common.CurrencyPrice<Bff.Products.DimensionalProductPrice<DimensionSet>>
type IngredientTypeCode = Bff.Common.IngredientTypeCode

const isSizeBaseDimensionSetArray = (item: DimensionSet[]): item is SizeBaseDimensionSet[] =>
  item[0]?.type === 'SizeBase'
const isSizeBaseDimensionSet = (item: DimensionSet): item is SizeBaseDimensionSet => item?.type === 'SizeBase'

const getDimensionSets = (legacyProduct: ProductMenuItem | PortionMenuItem | null) => {
  const dimensionSetType = getDimensionSetTypeFromProduct(legacyProduct)
  if (dimensionSetType === 'Size') {
    const sizeDimensionSets: SizeDimensionSet[] =
      legacyProduct?.sizes?.map((size) => ({ type: 'Size', size: size.code } as SizeDimensionSet)) ?? []

    return sizeDimensionSets
  }
  if (dimensionSetType === 'SizeBase') {
    const sizeBaseDimensionSets: SizeBaseDimensionSet[] =
      legacyProduct?.sizes?.flatMap(
        (size) =>
          size.swaps?.bases?.ingredients.map(
            (base) =>
              ({
                type: 'SizeBase',
                size: size.code ?? '',
                base: base.code ?? '',
              } as SizeBaseDimensionSet),
          ) ?? [],
      ) ?? []

    return sizeBaseDimensionSets
  }

  return [] as NonDimensionalSet[]
}
const getDimensionValues = (dimensionType: DimensionTypeCode, dimensionSets: DimensionSet[]) => {
  if (dimensionType === 'Size') {
    return Array.from(
      new Set(
        dimensionSets
          .map((dimension) => (dimension.type == 'Size' || dimension.type == 'SizeBase' ? dimension.size : ''))
          .filter((size) => size),
      ),
    )
  }
  if (dimensionType === 'Base') {
    return Array.from(
      new Set(
        dimensionSets.map((dimension) => (dimension.type == 'SizeBase' ? dimension.base : '')).filter((size) => size),
      ),
    )
  }

  return []
}
const getDimensionFilteredValues = (
  dimensionType: DimensionTypeCode,
  currentDimensionSet: DimensionSet | undefined,
  dimensionSets: Bff.Dimensions.DimensionSet[],
) => {
  if (!currentDimensionSet) return getDimensionValues(dimensionType, dimensionSets)
  if (dimensionType === 'Size') {
    if (isSizeBaseDimensionSetArray(dimensionSets) && isSizeBaseDimensionSet(currentDimensionSet)) {
      const filteredDimensionSets = dimensionSets.filter(
        (dimensionSet) => dimensionSet.base === currentDimensionSet.base,
      )

      return Array.from(new Set(filteredDimensionSets.map((dimensionSet) => dimensionSet.size)))
    }

    return []
  }
  if (dimensionType === 'Base') {
    if (isSizeBaseDimensionSetArray(dimensionSets) && isSizeBaseDimensionSet(currentDimensionSet)) {
      const filteredDimensionSets = dimensionSets.filter(
        (dimensionSet) => dimensionSet.size === currentDimensionSet.size,
      )

      return Array.from(new Set(filteredDimensionSets.map((dimensionSet) => dimensionSet.base)))
    }

    return []
  }

  return []
}
const isPortionCustomisable = (
  dimensionalProduct: Bff.Products.ProductBase<DimensionSet> | undefined,
  dimensionSet?: DimensionSet,
) => {
  if (
    !isDimensionalPortionProduct(dimensionalProduct) ||
    dimensionalProduct.portionProductIngredientTypeRules.length === 0
  ) {
    return false
  }

  return (
    dimensionalProduct.portionProductIngredientTypeRules.some((rule) => {
      const dimensionIngredientTypeRule = findEntityByDimensionSet(rule.rules, dimensionSet)

      return dimensionIngredientTypeRule?.isCommon === false
    }) || false
  )
}
const getMaxPortionSwapCount = (
  dimensionalProduct: Bff.Products.ProductBase<DimensionSet> | undefined,
  dimensionSet?: DimensionSet,
) => {
  if (!isDimensionalPortionProduct(dimensionalProduct)) {
    return 0
  }

  const dimensionalPortionProductRule = findEntityByDimensionSet(dimensionalProduct?.productRules || [], dimensionSet)

  return dimensionalPortionProductRule ? dimensionalPortionProductRule.maxPortionSwap : 0
}
const getPossiblePortionProducts = (
  itemsByCode: MenuItemDependentsDictionary | null | undefined,
  dimensionalProduct: Bff.Products.ProductBase<DimensionSet> | undefined,
  dimensionSet?: DimensionSet,
) => {
  if (!isDimensionalPortionProduct(dimensionalProduct)) return []
  const dimensionalPortionPossibleProducts = findEntityByDimensionSet(
    dimensionalProduct?.possibleProducts || [],
    dimensionSet,
  )

  return appendMediaToPortionPossibleProducts(itemsByCode, dimensionalPortionPossibleProducts ?? [])
}

const useProduct = (
  productCode: string | undefined,
  dimensionFilter?: DimensionFilter,
  shouldFetchOverride: boolean = false,
): ProductData => {
  const { itemsByCode } = useMenu()
  const { isEnabled } = useDevToggles()
  const menuItem = itemsByCode && productCode ? itemsByCode![productCode] : null
  const legacyProduct = menuItem && (isProductMenuItem(menuItem) || isPortionMenuItem(menuItem)) ? menuItem : null
  const { fetchProductByCode, product: dimensionalProduct, loading, error } = useProducts()
  const [toggleHalfHalfPortionProductCardEnabled] = useFeaturesV2('HalfHalfPortionProductCardEnabled')
  const isToggleRecipeProductCardEnabled = isEnabled && isEnabled['enable-recipe-product-card']
  const isToggleSimpleProductCardEnabled = isEnabled && isEnabled['enable-simple-product-card']

  const shouldFetch =
    legacyProduct &&
    (shouldFetchOverride ||
      (isPortionMenuItem(legacyProduct) &&
        (legacyProduct?.sizes?.[0]?.recipe?.portionCount === 4 ||
          (toggleHalfHalfPortionProductCardEnabled && legacyProduct?.sizes?.[0]?.recipe?.portionCount === 2))) ||
      (isToggleRecipeProductCardEnabled && getTypeFromProduct(legacyProduct) === 'Recipe') ||
      (isToggleSimpleProductCardEnabled && getTypeFromProduct(legacyProduct) === 'Simple'))

  useEffect(() => {
    //TODO: We must check the portion count here because we only want to fetch Quattros not HnH at the moment. If we fetch HnH we get an unwanted side-effect in the productSwitcher because the old ProductEditor is destroyed whilst the fetching takes place but when it reappears the editor's state isn't aware it is in HnH editing mode. Will be fixed in MAB-3034
    if (shouldFetch) {
      fetchProductByCode(legacyProduct.code)
    }
  }, [legacyProduct])
  const possibleDimensionSets = dimensionalProduct?.possibleDimensionSets ?? getDimensionSets(legacyProduct)
  const filteredPossibleDimensionSets = dimensionFilter
    ? filterDimensionSets(possibleDimensionSets, dimensionFilter)
    : possibleDimensionSets
  const defaultDimensionSet = () => {
    if (shouldFetch && !dimensionalProduct) {
      return undefined
    }
    const dimensionSet = dimensionalProduct?.defaultDimensionSet ?? getDefaultDimensionSetFromProduct(legacyProduct)
    if (!dimensionFilter) return dimensionSet
    if (dimensionSet && isDimensionSetValid(dimensionSet, filteredPossibleDimensionSets)) {
      return dimensionSet
    }

    return filteredPossibleDimensionSets[0]
  }
  //TODO: Refactor getDimensionFilteredValues out of the use-product.ts so that we don't need to drill function into createDimensionSet which has made to use this wrapper function
  const createDimensionSetWrapper = (
    previousDimensionSet: DimensionSet,
    dimensionType: 'Size' | 'Base',
    code: string,
    forced: boolean,
  ) =>
    createDimensionSet(
      previousDimensionSet,
      dimensionType,
      code,
      forced,
      defaultDimensionSet(),
      (dimensionSet: DimensionSet) => isDimensionSetValid(dimensionSet, filteredPossibleDimensionSets),
      (dimensionType, currentDimensionSet) =>
        getDimensionFilteredValues(dimensionType, currentDimensionSet, filteredPossibleDimensionSets),
    )

  return {
    get type() {
      return dimensionalProduct?.type ?? getTypeFromProduct(legacyProduct) ?? undefined
    },
    get code() {
      return legacyProduct ? legacyProduct.code : null
    },
    get isPortionProduct() {
      return this.type === 'Portion'
    },
    get dimensionSetType() {
      return dimensionalProduct?.dimensionSetType ?? getDimensionSetTypeFromProduct(legacyProduct) ?? 'NonDimensional'
    },
    get defaultDimensionSet() {
      return defaultDimensionSet()
    },
    isPortionCustomisable: (dimensionSet?: DimensionSet) => isPortionCustomisable(dimensionalProduct, dimensionSet),
    isDimensionSetValid: (dimensionSet: DimensionSet) =>
      isDimensionSetValid(dimensionSet, filteredPossibleDimensionSets),
    getDimensionValues: (dimensionType: DimensionTypeCode) =>
      getDimensionValues(dimensionType, filteredPossibleDimensionSets),
    getDimensionFilteredValues: (dimensionType: DimensionTypeCode, currentDimensionSet: DimensionSet | undefined) =>
      getDimensionFilteredValues(dimensionType, currentDimensionSet, filteredPossibleDimensionSets),
    getPossiblePortionProducts: (dimensionSet?: DimensionSet) =>
      getPossiblePortionProducts(itemsByCode, dimensionalProduct, dimensionSet),
    get defaultPortions() {
      return isDimensionalPortionProduct(dimensionalProduct) ? dimensionalProduct.portions : undefined
    },
    getMaxPortionSwapCount: (dimensionSet?: DimensionSet) => getMaxPortionSwapCount(dimensionalProduct, dimensionSet),
    get loading() {
      return loading
    },
    get errored() {
      return !!error
    },
    get media() {
      const media = legacyProduct?.media
      if (!media) return undefined

      return {
        name: { value: media.name || '' },
        description: { value: media.description || '' },
        smallImage: getImageMedia(media.smallImage),
        largeImage: getImageMedia(media.largeImage),
      }
    },
    getPriceDifferenceForDimensionChange: (selectedDimensionSet, dimensionType, dimensionCode) =>
      calculatePriceDifferenceForDimensionChange({
        currentDimensionSet: selectedDimensionSet,
        dimensionType,
        dimensionCode,
        currencyPrices:
          (dimensionalProduct &&
            'currencyPrices' in dimensionalProduct &&
            (dimensionalProduct.currencyPrices as CurrencyPrice[])) ||
          [],
        createDimensionSet: createDimensionSetWrapper,
      }),
    getIngredients: (ingredientType: IngredientTypeCode, dimensionSet?: DimensionSet) =>
      flattenIngredientsForDimensionSet(ingredientType, dimensionSet, dimensionalProduct),
    getIngredientTypeRule: (ingredientType: IngredientTypeCode, dimensionSet?: DimensionSet) =>
      flattenIngredientTypeRulesForDimensionSet(ingredientType, dimensionSet, dimensionalProduct),
  }
}

export { useProduct }
