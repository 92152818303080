import { allergensMockData, nutritionalsMockData } from '@dominos/business/functions/nutritional/index'
import {
  BottomBar,
  CountryToggle,
  createPortionProductCardBannerViewModel,
  PortionProductCardBanner,
  PortionProductCardTitle,
  ProductEditor,
  ProductEditorContentWrapper,
  ProductPortions,
  useProductContext,
} from '@dominos/components'
import { useBaseData, useSizeData } from '@dominos/components/product/functions'
import { ProductCustomiser } from '@dominos/components/product/product-customiser'
import { ProductNutritionalCard } from '@dominos/components/product/product-nutritional-feature-product'
import {
  ProductAllergen,
  ProductNutritionals,
} from '@dominos/components/product/product-nutritional-feature-product/product-nutritional.interface'
import { useBreakpoints, useFeaturesV2, useKiosk } from '@dominos/hooks-and-hocs'
import React from 'react'
import { PortionProductEditorProps } from './portion-product-editor.interface'
export const PortionProductEditor = ({
  testID,
  onDismiss,
  isEditing,
  onPortionCustomise,
  addToBasket,
  t,
}: PortionProductEditorProps) => {
  const { isMobile } = useBreakpoints()
  const { isKioskOrder } = useKiosk()
  const isMobileLayout = isMobile || isKioskOrder
  const {
    createBasketLine,
    dimensionSetState,
    portions,
    handlePortionChange,
    isValidForBasket,
    initialQuantity,
    resetPortion,
    formattedPrice,
    formattedPromoPrice,
    productData,
  } = useProductContext()
  const showContainsAllergensOnly = CountryToggle({
    FR: true,
    default: false,
  })
  const [nutritionalEnabled, nutritionalInfoButtonEnabled] = useFeaturesV2(
    'nutritionals',
    'ProductNutritionalInformationButton',
  )
  const sizeData = useSizeData(productData, dimensionSetState)
  const baseData = useBaseData(productData, dimensionSetState)
  const onSizeChange = (sizeCode: string) => {
    dimensionSetState?.handleDimensionChange('Size', sizeCode, true)
  }
  const onBaseChange = (baseCode: string) => {
    dimensionSetState?.handleDimensionChange('Base', baseCode)
  }
  //TODO: getNutritionalInfo() functionality needed to added with state menagement card
  const { productNutritionals, productAllergens } = nutritionalEnabled
    ? {
        productNutritionals: nutritionalsMockData as ProductNutritionals,
        productAllergens: allergensMockData as ProductAllergen[],
      }
    : { productNutritionals: null, productAllergens: [] }
  const addToBasketHandler = (quantity: number) => {
    addToBasket(createBasketLine(quantity))
    onDismiss(true)
  }

  return (
    <ProductEditor
      testID={`${testID}.product-editor`}
      onDismiss={onDismiss}
      showBackButton={!!isMobileLayout}
      showCloseButton={!isMobileLayout}
    >
      <ProductEditorContentWrapper testID={`${testID}.product-editor-content`}>
        <PortionProductCardTitle
          testID={testID}
          title={productData?.media?.name.value || ''}
          price={formattedPrice}
          promoPrice={formattedPromoPrice}
        />
        <PortionProductCardBanner testID={testID} viewModel={createPortionProductCardBannerViewModel({ t })} />
        <ProductPortions
          t={t}
          testID={`${testID}.product-portions`}
          portions={portions}
          products={productData?.getPossiblePortionProducts(dimensionSetState?.selectedDimensionSet) || []}
          onPortionProductChange={handlePortionChange}
          customisable={productData?.isPortionCustomisable(dimensionSetState?.selectedDimensionSet) || false}
          onPortionReset={resetPortion}
          onPortionCustomise={onPortionCustomise}
          isKioskOrder={isKioskOrder}
        />
        <ProductCustomiser
          sizeData={sizeData}
          onSizeChange={onSizeChange}
          baseData={baseData}
          onBaseChange={onBaseChange}
        />
        <ProductNutritionalCard
          nutritionals={productNutritionals}
          allergens={productAllergens}
          t={t}
          showContainsAllergensOnly={showContainsAllergensOnly}
          testID='nutritional-card'
          showInfoButton={nutritionalInfoButtonEnabled}
        />
      </ProductEditorContentWrapper>
      <BottomBar
        testID={`${testID}.bottom-bar`}
        onComplete={addToBasketHandler}
        isEditing={isEditing}
        initialQuantity={initialQuantity}
        isPortionProduct={false}
        forceDisabled={!isValidForBasket}
      />
    </ProductEditor>
  )
}
