import { useFeaturesV2 } from '@dominos/hooks-and-hocs'
import { VipOffersContextual, VipOffersGeneric, VipOffersOptOutAll, VipOffersOptOutIndividual, VipOffersProps } from '.'

export const useVipOffers = () => {
  const [
    initialVipOffersEmailChecked,
    initialVipOffersSMSChecked,
    vipOffersTCEnabled,
    contextualEmailSignUpEnabled,
    vipOffersOptOutAllEnabled,
    vipOffersOptOutIndividualEnabled,
  ] = useFeaturesV2(
    'InitialVipOffersEmailChecked',
    'InitialVipOffersSMSChecked',
    'VipOffersTCEnabled',
    'ContextualEmailSignupEnabled',
    'VipOffersOptOutSingleCheckbox',
    'VipOffersOptOutIndividualCheckboxes',
  )

  let VipOffersComponent: (props: VipOffersProps) => JSX.Element = VipOffersGeneric
  if (vipOffersOptOutAllEnabled) {
    VipOffersComponent = VipOffersOptOutAll
  } else if (vipOffersOptOutIndividualEnabled) {
    VipOffersComponent = VipOffersOptOutIndividual
  } else if (contextualEmailSignUpEnabled) {
    VipOffersComponent = VipOffersContextual
  }

  return {
    VipOffersComponent,
    vipOffersComponentProps: {
      initialVipOffersEmailChecked,
      initialVipOffersSMSChecked,
      initialVipOffersOptOutAll: false,
      initialVipOffersOptOutEmail: false,
      initialVipOffersOptOutSMS: false,
      vipOffersTCEnabled,
    },
  }
}
