import { getFullUrl, mapMenuItemToSauceItemData, productIngredientsTransform } from '@dominos/business/functions'
import { DimensionSetState } from '@dominos/hooks-and-hocs'
import { ProductUpsellComponentType } from '@dominos/components/product/product-upsell/product-upsell.interface'
import { getProductSize } from '@dominos/components/product/product-card/functions'

export const getImageUrl = (imageURL: string, isTestImgDevToggle: boolean) => {
  if (imageURL) {
    if (isTestImgDevToggle) {
      return `https://order.dominostest.com.au/${imageURL}`
    }

    return getFullUrl(imageURL)
  }

  return undefined
}

const isCrustAvailable = (dimensionSetState: DimensionSetState, offerCrustCode: string): boolean => {
  const availableBases = dimensionSetState.getDimensionValuesByType('Base')
  const selectedBase = dimensionSetState.getSelectedDimension('Base')

  return selectedBase !== offerCrustCode && availableBases?.includes(offerCrustCode)
}

const isSauceAvailable = (
  dimensionSetState: DimensionSetState,
  currentProduct: BasketLineData,
  offerSauceCode: string,
): boolean => {
  const selectedSize = dimensionSetState.getSelectedDimension('Size')
  const item = currentProduct.item as ProductMenuItem | PortionMenuItem
  const { elements: sauces, selected } = mapMenuItemToSauceItemData(item, selectedSize)
  const selectedSauceCode = currentProduct.sauce?.add ?? selected?.code

  const isOfferSauceSelected = selectedSauceCode === offerSauceCode
  const isOfferSauceAvailable = sauces?.some((element: SauceIconProps) => element.code === offerSauceCode)

  return !isOfferSauceSelected && isOfferSauceAvailable
}

const isToppingAvailable = (
  dimensionSetState: DimensionSetState,
  currentProduct: BasketLineData,
  offerToppingCode: string,
): boolean => {
  const selectedSize = dimensionSetState.getSelectedDimension('Size')
  const currentSize = getProductSize(currentProduct?.item, selectedSize)
  const toppings = currentProduct.toppings || []
  const { possibleIngredients: possibles, currentIngredients: currents } = productIngredientsTransform(
    toppings,
    currentSize,
  )

  const isOfferToppingAdded = currents?.some((element: IngredientItemProps) => element.code === offerToppingCode)
  const isOfferToppingAvailable = possibles?.some((element: IngredientItemProps) => element.code === offerToppingCode)

  const toppingMaxLimit = currentSize?.swaps?.toppings?.rule?.max ?? 0

  const isToppingUnderMaximumLimit = currents?.reduce((total, item) => total + item.quantity, 0) < toppingMaxLimit

  return !isOfferToppingAdded && isOfferToppingAvailable && isToppingUnderMaximumLimit && (currents?.length ?? 0) > 0
}

export const isOfferComponentAvailable = (
  offerComponentCode: string,
  offerComponentType: string | null,
  currentProduct: BasketLineData,
  dimensionSetState: DimensionSetState,
): boolean => {
  if (!offerComponentType) {
    return false
  }

  switch (offerComponentType) {
    case ProductUpsellComponentType.Crust:
      return isCrustAvailable(dimensionSetState, offerComponentCode)
    case ProductUpsellComponentType.Sauce:
      return isSauceAvailable(dimensionSetState, currentProduct, offerComponentCode)
    case ProductUpsellComponentType.Topping:
      return isToppingAvailable(dimensionSetState, currentProduct, offerComponentCode)
    default:
      return false
  }
}
