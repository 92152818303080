import { useLazyQuery } from '@apollo/client'
import { portionMenuItemQuery } from '@dominos/business/queries'
import { useFeaturesV2 } from '@dominos/hooks-and-hocs'
import { useEffect, useState } from 'react'

type PortionMenuItem = Bff.Menu.old.PortionMenuItem

const usePortionMenu = (
  storeNumber: number | undefined,
  serviceMethod: 'Pickup' | 'Delivery' | undefined,
  productCode: string,
) => {
  const [portionMenuItemData, setPortionMenuItem] = useState<PortionMenuItem>()
  const [halfHalfMenuWorkaround] = useFeaturesV2('HalfHalfMenuWorkaround')
  const [getPortionMenuItem, { data, error }] = useLazyQuery(portionMenuItemQuery)

  useEffect(() => {
    if (!portionMenuItemData && halfHalfMenuWorkaround && storeNumber && serviceMethod) {
      getPortionMenuItem({
        variables: {
          serviceMethod,
          productCode,
          storeNo: storeNumber,
        },
      })
    }
  }, [storeNumber, serviceMethod])

  useEffect(() => {
    if (data && data.portionMenuItem) {
      setPortionMenuItem(data.portionMenuItem)
    }
  }, [data])

  return {
    portionMenuItemData,
    error,
  }
}

export { usePortionMenu }
