import React, { useMemo } from 'react'

import { GenericSelect } from '@dominos/components'
import { useDevToggles } from '@dominos/hooks-and-hocs'
import { EmptyPortionProduct } from './empty-portion-product'
import { productToSelectItem } from './functions'
import { PortionActionButton } from './portion-action-button'
import { PortionProduct } from './portion-product'
import { PortionTileViewProps } from './portion-tile-view.interface'
import css from './portion-tile-view.less'
import classNames from 'classnames'

export const PortionTileView = ({
  viewModel: {
    portionIndex,
    portionKey,
    products,
    selectPlaceholderText,
    selectedProductCode,
    disabled,
    customiseButtonText,
    customisable,
    showReset,
    resetButtonText,
    isKioskOrder,
  },
  onSelectChange,
  onReset,
  onCustomise,
}: PortionTileViewProps): JSX.Element | null => {
  const testPrefix = `portion.tile-${portionIndex}`
  const selectedProduct = products.find((p) => p.code === selectedProductCode)
  const selectOptions = useMemo(() => products.map(productToSelectItem), [products])

  const handleChange = (code: string) => {
    onSelectChange(code)
  }
  const { isEnabled } = useDevToggles()
  const SELECT_HEIGHT = isKioskOrder ? 64 : 32

  const handleReset = () => {
    onReset(portionIndex)
  }
  const handleCustomise = () => {
    onCustomise()
  }

  return (
    <div
      className={classNames(css.cardWrapper, { [css.selected]: selectedProduct })}
      data-testid={`${testPrefix}.wrapper`}
    >
      {showReset && (
        <button onClick={handleReset} data-testid={`${testPrefix}.reset-button`} className={css.resetButton}>
          {resetButtonText}
        </button>
      )}

      {selectedProductCode ? (
        <PortionProduct imageUri={selectedProduct?.imageUri ?? ''} testID={`${testPrefix}.portion-product-image`} />
      ) : (
        <EmptyPortionProduct testID={`${testPrefix}`} portionKey={portionKey} />
      )}
      <div className={css.selectListWrapper}>
        <GenericSelect
          testID={`${testPrefix}.product-list`}
          options={selectOptions}
          placeholder={selectPlaceholderText}
          selectedValue={selectedProductCode}
          onChange={handleChange}
          accessibilityLabel={selectPlaceholderText}
          height={SELECT_HEIGHT}
          disabled={disabled}
        />
      </div>
      {isEnabled['show-portion-customize-button'] && customisable && selectedProductCode && (
        <div className={css.actionButtonWrapper}>
          <PortionActionButton
            text={customiseButtonText}
            onPress={handleCustomise}
            testID={`${testPrefix}.customise-button`}
          />
        </div>
      )}
    </div>
  )
}
