import { getFullUrl } from '@dominos/business/functions/menu'
import { useMarket } from '@dominos/hooks-and-hocs'
import { useMemo } from 'react'
import { ToppingProps } from '@dominos/components/product/functions/interfaces'

type IngredientModelWithQuantity = IngredientModel & { quantity: number }

const calculateQuantity = (change: BasketLineChange | undefined, topping: IngredientModel) => {
  const recipeQuantity = topping.inRecipe ? 1 : 0
  const changeQuantity = change?.quantity ?? 0
  const changeOperator = change?.action === 'Remove' ? -1 : 1

  return recipeQuantity + changeOperator * changeQuantity
}

const mapDimensionalRulesToLegacyRules = (
  rules: Bff.Products.ProductIngredientTypeRule | undefined,
): IngredientRules | undefined =>
  rules && {
    min: rules.minQuantity || 0,
    max: rules.maxQuantity || 0,
    allowAdd: rules.canAdd || false,
    maxPerIngredient: rules.maxQuantityPerIngredient || 0,
  }

const appendIngredientQuantity = (toppingIngredients: IngredientModel[], toppingChanges: BasketLineChange[]) =>
  toppingIngredients.map((ingredient) => {
    const change = toppingChanges.find((change) => change.code === ingredient.code)
    const quantity = calculateQuantity(change, ingredient)

    return { ...ingredient, quantity }
  })

const useToppingMedia = (toppingIngredients: IngredientModelWithQuantity[]): IngredientItemProps[] => {
  const { getMediaForIngredient } = useMarket()

  return toppingIngredients.map((ingredient) => {
    const media = getMediaForIngredient('Topping', ingredient.code)
    const name = media?.name.value ?? ''
    const thumb = getFullUrl(media?.image?.uri ?? '')

    return {
      code: ingredient.code,
      name: name,
      thumb: thumb,
      title: name,
      quantity: ingredient.quantity,
      value: ingredient.price,
      allowAdd: ingredient.quantity === 0 && ingredient.inRecipe ? true : ingredient.canAdd,
      inRecipe: ingredient.inRecipe,
      minimumReached: ingredient.quantity <= 0,
    }
  })
}

const getCurrentIngredients = (toppingIngredients: IngredientModel[], toppingChanges: BasketLineChange[]) => {
  const recipeIngredients = toppingIngredients.filter((x) => x.inRecipe)

  const addedIngredients = toppingChanges.flatMap((change) => {
    const ingredient = toppingIngredients.find((x) => x.code === change.code && !x.inRecipe)

    return ingredient ? [ingredient] : []
  })

  return appendIngredientQuantity([...recipeIngredients, ...addedIngredients], toppingChanges)
}

const useCurrentIngredients = (toppingIngredients: IngredientModel[], toppingChanges: BasketLineChange[]) => {
  const currentIngredients = getCurrentIngredients(toppingIngredients, toppingChanges)

  return useToppingMedia(currentIngredients)
}

const usePossibleIngredients = (
  toppingIngredients: IngredientModel[],
  toppingChanges: BasketLineChange[],
): IngredientItemProps[] => {
  const possibleIngredients = appendIngredientQuantity(
    toppingIngredients.filter((x) => x.canAdd),
    toppingChanges,
  )

  return useToppingMedia(possibleIngredients)
}

const useToppingData = (
  toppingIngredients: IngredientModel[],
  toppingChanges: BasketLineChange[] | undefined,
  ingredientRules: Bff.Products.ProductIngredientTypeRule | undefined,
) => {
  const changes = toppingChanges || []
  const currentIngredients = useCurrentIngredients(toppingIngredients, changes)
  const possibleIngredients = usePossibleIngredients(toppingIngredients, changes)

  return useMemo<ToppingProps>(
    () => ({
      recipeIngredientCodes: toppingIngredients.filter((topping) => topping.inRecipe).map((topping) => topping.code),
      ingredientRules: mapDimensionalRulesToLegacyRules(ingredientRules),
      currentIngredients: currentIngredients,
      possibleIngredients: possibleIngredients,
      changes,
    }),
    [toppingIngredients, toppingChanges, ingredientRules],
  )
}

export { useToppingData, getCurrentIngredients }
