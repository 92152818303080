export const togglesConfig = {
  /**
   * This toggle is used to display combo upsell component on PDP
   * we will keep this until the combo upsell is fully implemented
   */
  'combo-upsell': false,
  /**
   * This toggle is used to display inline upsell component on basket page
   * will keep this until the inline upsell is fully implemented
   */
  'inline-upsell': false,
  /**
   * IMPORTANT! Feature already enabled in prod, but the toggle is kept to allow e2e to toggle it off
   */
  'upsell-popups': true,

  /**
   * For offer and banner images, this toggle switches from the prod host (default) to the staging
   * host address to allow images to appear for offers and banners that are created in the test CMS.
   */
  'test-offers-images': false,
  /**
   * For flexibility during e2e testing, providing a gift card holder name allows us to test various
   * scenarios such as NOT_ENOUGH_BALANCE. This flag MUST be off in production.
   */
  'gift-card-holder-name': false,
  /**
   * For flexibility during e2e testing, limiting the payment balance to 1 allows us to test split payment.
   * This flag MUST be off in production.
   */
  'limit-payment-balance': false,
  /**
   * Disables the display of Rokt advertisement popups for use during e2e tests. This prevent UI blocking
   * on the tracking page, where Rokt consumes the users screen with a popup advertisement experience.
   */
  'disable-rokt-advertisements': false,
  /**
   * For persisted query link
   */
  'persisted-query': false,
  /**
   * For persisted query to use GET or POST method
   */
  'use-get-for-persisted-query': false,
  /**
   * This toggle is used to display the swrve embedded campaign popup that is configured in swrve console.
   * This Feature is enabled in prod, but the toggle is kept to allow e2e to toggle it off
   */
  'swrve-embedded-campaign-popup': true,
  /**
   * This toggle is used to display the customize button under each portion product tile in the product page.
   */
  'show-portion-customize-button': true,
  /**
   * This toggle is used to display the New Recipe Product Card.
   */
  'enable-recipe-product-card': false,
  /**
   * This toggle is used to display the Simple Recipe Product Card.
   */
  'enable-simple-product-card': false,
}
