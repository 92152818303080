import { getDimensionFromDimensionSet } from '@dominos/hooks-and-hocs/menu/functions'

const mapSauce = ({ add }: Pick<BasketLineSwap, 'add'>): Bff.PriceProduct.PriceProductComponent => ({
  componentCode: add || '',
  quantity: 1,
})

const mapProductContextToPriceProduct = (
  code: string,
  dimensionSet: Bff.Dimensions.DimensionSet,
  portions: Portion[],
): Bff.PriceProduct.PriceProduct => {
  let priceProduct: Bff.PriceProduct.PriceProduct = {
    quantity: 1,
    productCode: code,
    productSizeCode: getDimensionFromDimensionSet('Size', dimensionSet) || '',
    portions: portions.map((portion) => ({
      productCode: portion.productCode!,
      sauces: portion.sauce ? [mapSauce(portion.sauce)] : [],
      toppings: [],
      options: [],
    })),
  }

  const base = getDimensionFromDimensionSet('Base', dimensionSet)

  if (base) {
    priceProduct = {
      ...priceProduct,
      crusts: [{ componentCode: base, quantity: 1 }],
    }
  }

  return priceProduct
}

export { mapProductContextToPriceProduct }
