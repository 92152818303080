import React from 'react'
import {
  CompletionTrack,
  createCompletionTrackViewModel,
  createGetFormattedPrice,
  useLoyaltyBasketInfoStore,
} from 'olo-feature-loyalty'
import { useTranslation } from 'react-i18next'
import { getCountryConfig } from '@dominos/business/functions'
import { useBasket, useCurrentOrderDetails, useCustomer, useFeaturesV2 } from '@dominos/hooks-and-hocs'
import { useApolloClient } from '@apollo/client'

export const LoyaltyCompletionTrack = () => {
  const { t } = useTranslation('loyalty')
  const { CURRENCY_CODE: currencyCode, CURRENCY_FORMAT_LOCALE: locale } = getCountryConfig()
  const { basket } = useBasket()
  const { customer } = useCustomer()
  const [loyaltyV2Enabled] = useFeaturesV2('LoyaltyV2Enabled')
  const { basketHeaderData } = useCurrentOrderDetails()

  const loyaltyBasketInfoStore = useLoyaltyBasketInfoStore({
    customerId: customer?.id,
    client: useApolloClient(),
    featureEnabled: loyaltyV2Enabled,
  })

  if (!loyaltyBasketInfoStore) return null

  const getFormattedPrice = createGetFormattedPrice({ locale, currencyCode })
  const viewModel = createCompletionTrackViewModel({
    t,
    getFormattedPrice,
    loyaltyBasketInfoStore,
    basket: { ...basketHeaderData, ...basket },
  })

  return <CompletionTrack viewModel={viewModel} />
}
