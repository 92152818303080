import { BasketLineData } from '@dominos/business/functions/basket'
import { useFeaturesV2, useIsAnzDelivery, usePriceProduct, useProductPricing } from '@dominos/hooks-and-hocs'

import { useEffect } from 'react'
import { getFormattedPrice } from '@dominos/business/functions/text'

export const useDynamicPricing = () => {
  const [dynamicPricing, setDynamicPricing] = useProductPricing()
  const [dynamicPricingEnabled, salePriceForPickupEnabled] = useFeaturesV2('dynamic-pricing', 'SalePriceForPickup')
  const { fetchPrice, data, error } = usePriceProduct()

  const isAnzDelivery = useIsAnzDelivery()
  const queryEnabled = dynamicPricingEnabled && !isAnzDelivery

  const hasCustomisations = (product: BasketLineData) =>
    !!(product.base || product.toppings || product.sauce || product.sizeCode)

  const shouldFetchPrice = (product: BasketLineData, productPricing: ProductPricing) =>
    queryEnabled &&
    (hasCustomisations(product) ||
      productPricing.formattedPrice ||
      (productPricing.formattedSalePrice && salePriceForPickupEnabled))

  useEffect(() => {
    if (!!data?.priceProduct) {
      setDynamicPricing({
        formattedPrice: data.priceProduct.displayTotal,
        formattedSalePrice: getFormattedPrice(data.priceProduct.salePrice),
        formattedPromoPrice: data.priceProduct.displayPromoTotal,
      })
    }
  }, [data])

  return {
    shouldFetchPrice,
    fetchPrice,
    dynamicPricing,
    error,
  }
}
