import React, { createContext, ReactNode, useContext, useState } from 'react'
import { getCountryConfig } from '@dominos/business/functions/common'
import { B2BAuth } from './B2BAuth'
import { useEffect } from 'react'
import { useFeaturesV2 } from '@dominos/hooks-and-hocs'
import { BrowserCacheLocation } from '@azure/msal-browser'

interface IAuthProviderB2BProps {
  children: ReactNode
}

const AuthContext = createContext(false)

export const AuthProviderB2B = ({ children }: IAuthProviderB2BProps) => {
  const config = getCountryConfig()
  const [isB2BOrderEnabled] = useFeaturesV2('IsB2BOrderEnabled')
  const configuration = {
    auth: {
      clientId: config.B2B_AZURE_AD_CLIENT_ID,
      redirectUri: '/',
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: true, // set to true for IE 11
    },
  }
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  const loginConfig = { scopes: [`api://${config.B2B_AZURE_AD_CLIENT_ID}/access_as_user`] }

  useEffect(() => {
    if (!isB2BOrderEnabled) return

    B2BAuth.init(configuration, loginConfig, setIsAuthenticated)
  }, [isB2BOrderEnabled])

  return <AuthContext.Provider value={isAuthenticated}>{children}</AuthContext.Provider>
}

export const useIsAuthenticated = () => useContext(AuthContext)
