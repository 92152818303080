import {
  useAccountDetailsSubmenuToggles,
  useBreakpoints,
  useCustomer,
  useDominosTheme,
  useFeaturesV2,
} from '@dominos/hooks-and-hocs'
import { StaticSvgIcon } from '@dominos/res'
import { Link, navigate, Redirect, RouteComponentProps, Router } from '@reach/router'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavigationConstants } from '../../common/navigation/navigation-constants'
import { NewVouchersIndicator } from '@dominos/components/new-vouchers-indicator'
import { DeleteAccount } from './delete-account'
import styles from './my-account-layout.less'
import { MyDetails } from './my-details'
import { MyPassword } from './my-password'
import { MyRewards } from './my-rewards'
import { MySavedPayments } from './my-saved-payments'
import { MySavedVouchers } from './my-saved-vouchers'
import { SubMenuToggles } from '@dominos/interfaces'

interface SubMenuProps extends RouteComponentProps {
  subMenuToggles: SubMenuToggles
}

interface ContentPaneProps {
  defaultRoute: string
  subMenuToggles: SubMenuToggles
}

const Routes = (redirect?: string, subMenuToggles?: SubMenuToggles) => (
  <>
    {redirect ? <Redirect noThrow from={'/'} to={redirect} /> : null}
    {subMenuToggles?.myRewards ? <MyRewards path={'my-rewards'} /> : <Redirect noThrow from={'my-rewards'} to={'/'} />}
    {subMenuToggles?.myAccountDetails ? <MyDetails path={'details'} /> : <Redirect noThrow from={'details'} to={'/'} />}
    {subMenuToggles?.myAccountPassword ? (
      <MyPassword path={'password'} />
    ) : (
      <Redirect noThrow from={'password'} to={'/'} />
    )}
    {subMenuToggles?.myAccountSavedPayments ? (
      <MySavedPayments path={'payment-details'} />
    ) : (
      <Redirect noThrow from={'payment-details'} to={'/'} />
    )}
    {subMenuToggles?.savedVouchers ? (
      <MySavedVouchers path={'vouchers'} />
    ) : (
      <Redirect noThrow from={'vouchers'} to={'/'} />
    )}
    {subMenuToggles?.myAccountDelete ? (
      <DeleteAccount path={'delete'} />
    ) : (
      <Redirect noThrow from={'delete'} to={'/'} />
    )}
  </>
)

const MyAccountLayout = () => {
  const { isMobile } = useBreakpoints()
  const { isLoggedIn } = useCustomer()
  const { subMenuToggles, getFirstActiveToggle } = useAccountDetailsSubmenuToggles()
  const toggles = subMenuToggles()
  const firstActiveToggle = getFirstActiveToggle()

  if (isLoggedIn === false) {
    navigate(NavigationConstants.home)
  }

  return isLoggedIn ? (
    <div className={styles.page} data-testid='account-navigation'>
      <div className={styles.wrapper}>
        <Router className={styles.submenuMobile}>
          <SubMenu path={'/'} subMenuToggles={toggles} default />
          {isMobile ? Routes(undefined, toggles) : null}
        </Router>
        {isMobile ? null : <ContentPane defaultRoute={firstActiveToggle} subMenuToggles={toggles} />}
      </div>
    </div>
  ) : null
}

const SubMenu = ({ path, subMenuToggles }: SubMenuProps) => {
  const theme = useDominosTheme()
  const { t } = useTranslation('login')
  const [notificationBadgeMySavedCoupons] = useFeaturesV2('NotificationBadgeMySavedCoupons')

  return (
    <ul className={styles.submenu} data-testid='my-account-submenu'>
      {subMenuToggles.myRewards && (
        <li className={styles.submenuButton}>
          <Link to={'/my-account/my-rewards'}>
            <h3>{t('MyAccountRewardsHeading', { defaultValue: 'My Rewards' })}</h3>
            <StaticSvgIcon name={'chevron'} isUnstyled={true} fill={theme.colours.overlayColor} direction={'right'} />
          </Link>
        </li>
      )}
      {subMenuToggles.myAccountDetails && (
        <li className={styles.submenuButton}>
          <Link to={'/my-account/details'}>
            <h3>{t('AccountDetailsHeading', { defaultValue: 'My Details' })}</h3>
            <StaticSvgIcon name={'chevron'} isUnstyled={true} fill={theme.colours.overlayColor} direction={'right'} />
          </Link>
        </li>
      )}
      {subMenuToggles.myAccountPassword && (
        <li className={styles.submenuButton}>
          <Link to={'/my-account/password'}>
            <h3>{t('MyAccountPasswordHeading', { defaultValue: 'My Password' })}</h3>
            <StaticSvgIcon name={'chevron'} isUnstyled={true} fill={theme.colours.overlayColor} direction={'right'} />
          </Link>
        </li>
      )}
      {subMenuToggles.myAccountSavedPayments && (
        <li className={styles.submenuButton}>
          <Link to={'/my-account/payment-details'}>
            <h3>{t('MyAccountPaymentsHeading', { defaultValue: 'My Saved Payments' })}</h3>
            <StaticSvgIcon name={'chevron'} isUnstyled={true} fill={theme.colours.overlayColor} direction={'right'} />
          </Link>
        </li>
      )}
      {subMenuToggles.savedVouchers && (
        <li className={styles.submenuButton}>
          <Link to={'/my-account/vouchers'}>
            <h3>{t('MyAccountSavedVouchersTitle', { defaultValue: 'My Saved Vouchers' })}</h3>
            {notificationBadgeMySavedCoupons && <NewVouchersIndicator showCount={true} />}
            <StaticSvgIcon name={'chevron'} isUnstyled={true} fill={theme.colours.overlayColor} direction={'right'} />
          </Link>
        </li>
      )}
      {subMenuToggles.myAccountDelete && (
        <li className={styles.submenuButton}>
          <Link to={'/my-account/delete'}>
            <h3>{t('MyAccountSubMenuDeleteAccount', { defaultValue: 'Delete Account' })}</h3>
            <StaticSvgIcon name={'chevron'} isUnstyled={true} fill={theme.colours.overlayColor} direction={'right'} />
          </Link>
        </li>
      )}
    </ul>
  )
}

const ContentPane = ({ defaultRoute, subMenuToggles }: ContentPaneProps) => (
  <div className={styles.contentPane} data-testid='my-account-content-pane'>
    <Router>{Routes(defaultRoute, subMenuToggles)}</Router>
  </div>
)

export { MyAccountLayout }
