import { ApolloError, useLazyQuery } from '@apollo/client'
import { getCountryConfig } from '@dominos/business/functions/common/get-config'
import { availableStorePaymentMethodsQuery } from '@dominos/business/queries'
import { useFeaturesV2, useKiosk } from '@dominos/hooks-and-hocs'
import { PaymentProvidersResponse } from '@dominos/interfaces'
import { useEffect, useState } from 'react'

export type PaymentSetting = Pick<
  Bff.Stores.StorePaymentSetting,
  | 'paymentMethod'
  | 'providerCode'
  | 'properties'
  | 'savedPayment'
  | 'donationEnabled'
  | 'completeSplitPaymentEnabled'
  | 'accountId'
> & { orderPaymentId: string }

export const defaultPaymentProvider: PaymentSetting[] = []

const isAllowedPayment = (
  outstandingBalance: boolean | undefined,
  method: Pick<Bff.Stores.StorePaymentSetting, 'completeSplitPaymentEnabled' | 'providerCode' | 'paymentMethod'>,
) =>
  !outstandingBalance ||
  (outstandingBalance && method.completeSplitPaymentEnabled && !isGiftCard(method.providerCode, method.paymentMethod))

const isGiftCard = (providerCode: string, paymentMethod: string) =>
  providerCode === 'Adyen' && paymentMethod === 'GiftCard'

const comparePaymentMethodOrder = (
  a: Bff.Stores.StorePaymentSetting,
  b: Bff.Stores.StorePaymentSetting,
  displayOrder: string[],
) => {
  const paymentMethodA = `${a.savedPayment ? 'Saved' : ''}${a.paymentMethod}`
  const paymentMethodB = `${b.savedPayment ? 'Saved' : ''}${b.paymentMethod}`

  if (!displayOrder.includes(paymentMethodA)) {
    return 1
  }

  if (!displayOrder.includes(paymentMethodB)) {
    return -1
  }

  return displayOrder.indexOf(paymentMethodA) - displayOrder.indexOf(paymentMethodB)
}

export const usePaymentsAvailableAtStore = (
  storeNo: number | undefined,
  serviceMethod: BffContext.ServiceMethods | undefined,
  serviceMethodSubType: Bff.Stores.ServiceMethodSubTypes | undefined,
  orderTime?: string | undefined,
  outstandingBalance?: boolean,
): {
  loading: boolean
  error?: ApolloError
  paymentSettings?: PaymentSetting[]
  called: boolean
} => {
  const { isValidKioskOrder, kioskId } = useKiosk()
  const [paymentSettings, setPaymentSettings] = useState<PaymentSetting[]>()
  const [roundUpForCharityEnabled, adyenGivingEnabled, injectKioskHeaderEnabled] = useFeaturesV2(
    'charity-round-up',
    'AdyenGiving',
    'InjectKioskHeader',
  )

  const withDonationEnabled = roundUpForCharityEnabled || adyenGivingEnabled
  const addKioskHeader = isValidKioskOrder && injectKioskHeaderEnabled

  const [fetchAvailablePayments, { loading, error, data, called }] = useLazyQuery<PaymentProvidersResponse>(
    availableStorePaymentMethodsQuery,
    addKioskHeader ? { context: { headers: { 'x-forwarded-for': kioskId } } } : undefined,
  )

  const sortMethods = (payments: Bff.Stores.StorePaymentSetting[]) => {
    const displayOrder = getCountryConfig().PAYMENT_METHOD_DISPLAY_ORDER

    if (!displayOrder || displayOrder === 'null') {
      return payments
    }

    return [...payments].sort((a, b) => comparePaymentMethodOrder(a, b, displayOrder.split(',')))
  }

  const filterMethods = (payments: Bff.Stores.StorePaymentSetting[]) =>
    payments.filter(
      (method) =>
        (serviceMethod === 'Delivery' ? method.deliveryEnabled : method.pickupEnabled) &&
        isAllowedPayment(outstandingBalance, method),
    )

  useEffect(() => {
    if (!data && paymentSettings) {
      setPaymentSettings(paymentSettings.filter((method) => isAllowedPayment(outstandingBalance, method)))
    }
  }, [outstandingBalance])

  useEffect(() => {
    if (!!storeNo) {
      fetchAvailablePayments({
        variables: { storeNo, serviceMethod, serviceMethodSubType, orderTime, withDonationEnabled },
      })
    }
  }, [storeNo])

  useEffect(() => {
    if (!called || loading) {
      return
    }

    if (error || !data?.availablePayments?.length) {
      setDefault()

      return
    }

    const availablePayments = sortMethods(data.availablePayments)
    const filteredMethods = filterMethods(availablePayments).map((payment) => ({
      ...payment,
      orderPaymentId: '',
    }))

    if (filteredMethods.length > 0) {
      setPaymentSettings(filteredMethods)
    } else {
      setDefault()
    }
  }, [called, loading, error, data, serviceMethod, outstandingBalance])

  const setDefault = () => setPaymentSettings(defaultPaymentProvider)

  return {
    loading,
    error,
    paymentSettings,
    called,
  }
}
