import { rootActions } from '@dominos/business'
import { useSdkAvailable } from '@dominos/hooks-and-hocs'
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

export enum CookieCategory {
  StrictlyNecessary = '1',
  Analytic = '109',
  Performance = '2',
  Functional = '3',
  AdvertAndSocialMedia = '4',
}

declare global {
  interface Window {
    OnetrustActiveGroups?: string
    OneTrust?: {
      OnConsentChanged: (callback: (e: { detail: CookieCategory[] }) => void) => {}
      ToggleInfoDisplay: () => void
    }
    OTExternalConsent?: object
  }
}

// Defaulted to true because AU/NZ markets dont use OT and are required to have everything enabled.
// OT will turn off the catagories as needed in EU markets
const initialConsents: {
  StrictlyNecessary: boolean
  Analytic: boolean
  Performance: boolean
  Functional: boolean
  AdvertAndSocialMedia: boolean
} = {
  StrictlyNecessary: true,
  Analytic: true,
  Performance: true,
  Functional: true,
  AdvertAndSocialMedia: true,
}

const OneTrustContext = createContext(initialConsents)

interface OneTrustProps {
  children: ReactNode
}

export const OneTrustProvider = (props: OneTrustProps) => {
  const [consents, setConsents] = useState(initialConsents)
  const [initialised, setInitialised] = useState(false)
  const { found } = useSdkAvailable('OneTrust')
  const dispatch = useDispatch()

  useEffect(() => {
    if (found) {
      setup()
    }
  }, [found])

  useEffect(() => {
    if (initialised) {
      Object.entries(CookieCategory).forEach(([key, value]) => {
        if (!consents[key as keyof typeof consents]) {
          dispatch(rootActions.consentNotGiven(value))
        }
      })
    }
  }, [consents, initialised])

  const isCategoryActive = (activeCategories: CookieCategory[], category: CookieCategory): boolean =>
    activeCategories.findIndex((ag) => ag === category) >= 0

  const parseConsents = (activeCategories: CookieCategory[]) => ({
    StrictlyNecessary: isCategoryActive(activeCategories, CookieCategory.StrictlyNecessary),
    Analytic: isCategoryActive(activeCategories, CookieCategory.Analytic),
    Performance: isCategoryActive(activeCategories, CookieCategory.Performance),
    Functional: isCategoryActive(activeCategories, CookieCategory.Functional),
    AdvertAndSocialMedia: isCategoryActive(activeCategories, CookieCategory.AdvertAndSocialMedia),
  })

  const setup = () => {
    if (window.OnetrustActiveGroups) {
      const activeGroups = window.OnetrustActiveGroups.split(',').filter((ag) => !!ag) as CookieCategory[]
      const parsedConsents = parseConsents(activeGroups)
      setConsents(parsedConsents)
      setInitialised(true)
    }

    const waitForOneTrust = (callback: () => void) => {
      const interval = setInterval(() => {
        if (window.OneTrust && typeof window.OneTrust.OnConsentChanged === 'function') {
          clearInterval(interval)
          callback()
        }
      }, 100)
    }

    waitForOneTrust(() => {
      window.OneTrust!.OnConsentChanged((e: { detail: CookieCategory[] }) => {
        const parsedConsents = parseConsents(e.detail)
        setConsents(parsedConsents)
      })
    })
  }

  return <OneTrustContext.Provider value={consents}>{props.children}</OneTrustContext.Provider>
}

export const useOneTrust = () => useContext(OneTrustContext)
