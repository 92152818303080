import { useSessionStorage } from '@dominos/hooks-and-hocs'

export type UseLaunchDarklyIDReturnType = {
  launchDarklyID: string
  setLaunchDarklyID: (value: string) => void
}

export const useLaunchDarklyID = (): UseLaunchDarklyIDReturnType => {
  const { setValue, storedValue } = useSessionStorage({ key: 'launch-darkly-id' })

  return {
    launchDarklyID: window.nativeAppLaunchDarklyId ?? storedValue,
    setLaunchDarklyID: setValue,
  }
}
