import { useFlags } from 'launchdarkly-react-client-sdk'
import { useMemo } from 'react'
import { getDefaultFeatureFlags, getFeatureFlags } from './feature-flag-helper'
import { FeatureFlagType } from '../feature-flag.interface'

export const useLaunchDarklyFlags = (): { flags: FeatureFlagType } => {
  const sdkFlags = useFlags()
  const flags = useMemo(() => getFeatureFlags(sdkFlags) ?? getDefaultFeatureFlags() ?? {}, [sdkFlags])

  return { flags }
}
