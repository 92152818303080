import React, { CSSProperties, useEffect, useMemo, useState } from 'react'
import { useFeaturesV2 } from '@dominos/hooks-and-hocs'
import {
  DeliverySearchViewMode,
  IUseConfirmDeliverySearchActionContainerProps,
  useConfirmDeliverySearchActionContainer,
} from 'olo-feature-address'

import { ConfirmAddressComponent } from '../confirm-address-component'
import { DeliveryAddressErrorContainer } from '../delivery-address-error-container'
import { AddressAttributeValidationErrorCode, DeliveryAddressErrorCodes } from 'olo-feature-address'

export interface DeliverySearchActionConfirmContainerProps extends IUseConfirmDeliverySearchActionContainerProps {
  viewMode: DeliverySearchViewMode
}

const errorCodesForHidingActionButtons = [
  AddressAttributeValidationErrorCode.MissingRequiredAddressAttribute,
  DeliveryAddressErrorCodes.NoAddressFoundForLocation,
]

export const DeliverySearchActionConfirmContainer = (props: DeliverySearchActionConfirmContainerProps) => {
  const [das_ShowActionButtonsOnSecondFail] = useFeaturesV2('DeliveryAddressSearch-ShowActionButtonsOnSecondFail')
  const {
    navigationAction,
    displayConfirmAddressContainer,
    currentError,
    viewMode,
    onClose,
    deliveryAddressChange,
    deliveryAddress,
  } = useConfirmDeliverySearchActionContainer(props)

  const [incompleteAddressCount, setIncompleteAddressCount] = useState(0)

  useEffect(() => {
    if (errorCodesForHidingActionButtons.some((errorCode) => errorCode === currentError?.errorCode)) {
      setIncompleteAddressCount(incompleteAddressCount + 1)
    }
  }, [currentError])

  const hideActionButtons = useMemo(
    () =>
      das_ShowActionButtonsOnSecondFail &&
      incompleteAddressCount < 2 &&
      errorCodesForHidingActionButtons.some((errorCode) => errorCode === currentError?.errorCode),
    [currentError, incompleteAddressCount],
  )

  const containerStyle = getStyles()
  const containerBaseOnViewStyle =
    props.viewMode === 'Map'
      ? { ...containerStyle.container, ...containerStyle.containerMap }
      : { ...containerStyle.container, ...containerStyle.containerList }

  if (displayConfirmAddressContainer)
    return (
      <div style={containerBaseOnViewStyle}>
        <ConfirmAddressComponent
          deliveryAddress={deliveryAddress}
          deliveryAddressChange={deliveryAddressChange}
          testID={'delivery-search-action-confirm'}
          navigationAction={navigationAction}
        />
      </div>
    )

  return (
    <div style={containerBaseOnViewStyle}>
      <DeliveryAddressErrorContainer
        currentError={currentError}
        currentComponent={'ConfirmAddress'}
        viewMode={viewMode}
        onClose={onClose}
        navigationAction={navigationAction}
        displayAsCard={true}
        deliveryAddress={deliveryAddress}
        hideActionButtons={hideActionButtons}
      />
    </div>
  )
}

interface DeliverySearchActionConfirmStyle {
  container: CSSProperties
  containerMap: CSSProperties
  containerList: CSSProperties
}

const getStyles = (): DeliverySearchActionConfirmStyle => ({
  container: {
    width: '360px',
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0.5px 10px 0px',
    borderRadius: '15px',
    backgroundColor: 'white',
    pointerEvents: 'auto',
  },
  containerMap: {
    bottom: '8px',
    marginTop: '0px',
  },
  containerList: {
    top: '8px',
  },
})
