import { MenuNavCategories, MenuNavigationProps, MenuNavSelections } from '@dominos/components'
import { useDominosTheme, useHasTopMenuWrapped } from '@dominos/hooks-and-hocs'
import React from 'react'
import less from './menu-navigation.less'
import { MenuCategoryTabs } from './menu-category-tabs'
import { useFeaturesV2 } from '@dominos/hooks-and-hocs'
import { isNativeApp } from '@dominos/business/functions'

export const MenuNavigation: React.FC<MenuNavigationProps> = (props: MenuNavigationProps) => {
  const styles = useDominosTheme()
  const topMenuWrapped = useHasTopMenuWrapped()
  const [topNavMenuMobileWeb] = useFeaturesV2('TopNavMenuMobileWeb')
  const { menuCategory } = props
  const showCategoryTabs = menuCategory && topMenuWrapped && topNavMenuMobileWeb

  return (
    <nav id='menu-navigation' className={less.wrapper} style={{ boxShadow: styles.lightShadow }}>
      {isNativeApp() ? null : <MenuNavCategories {...props} />}
      {showCategoryTabs ? <MenuCategoryTabs {...props} /> : <MenuNavSelections {...props} />}
    </nav>
  )
}

MenuNavigation.defaultProps = {
  showMenu: true,
  showBackButton: true,
}
