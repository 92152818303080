import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { AdditionalField } from '../additional-field'
import { getNativeFeatures, useValidation, useValidationJP } from '@dominos/business/functions'
import { useFeaturesV2 } from '@dominos/hooks-and-hocs'
import { getAddressComponentFromDeliveryAddress } from '@dominos/business/functions/address/autocomplete'

const UNIT_NO_MAX_LENGTH = 9

export const AdditionalFieldsLayoutJp = ({ testID, address, onInit, onChange }: AdditionalFieldsLayoutProps) => {
  const { t } = useTranslation('delivery-address')
  const { maxLength, isRequired } = useValidation()
  const { isNumeric, validateHouseNumber } = useValidationJP()
  const [webAutoCompleteDisplayAdditionalFieldsCheckout] = useFeaturesV2(
    'AutoCompleteDeliveryAddressFieldsDisplayCheckout',
  )
  const nativeAppAutoCompleteDisplayAdditionalFieldsCheckout = getNativeFeatures(
    'AutoCompleteDeliveryAddressFieldsDisplayCheckout',
  )
  const autoCompleteDisplayAdditionalFieldsCheckout =
    webAutoCompleteDisplayAdditionalFieldsCheckout || nativeAppAutoCompleteDisplayAdditionalFieldsCheckout

  const shouldValidateStreetNoOnInit = !!getAddressComponentFromDeliveryAddress(address, 'streetNo')?.value

  useEffect(() => {
    if (onInit)
      onInit({
        streetNo: {
          isValid: shouldValidateStreetNoOnInit,
        },
        buildingName: {
          isValid: true,
        },
        unitNo: {
          isValid: true,
        },
      })
  }, [])

  return (
    <>
      <AdditionalField
        testID={testID}
        fieldName={'streetNo'}
        placeholder={t('AddressFieldPlaceholder', { defaultValue: 'Rest Of Address' })}
        onChange={onChange}
        validationRules={[isRequired, validateHouseNumber]}
        address={address}
        hideWhenHasValue={true}
      />
      <AdditionalField
        testID={testID}
        fieldName={'buildingName'}
        placeholder={t('NewBuildingNameFieldPlaceholder', { defaultValue: 'Building Name' })}
        onChange={onChange}
        validationRules={undefined}
        address={address}
        disableWhenHasValue={!autoCompleteDisplayAdditionalFieldsCheckout}
      />
      <AdditionalField
        testID={testID}
        fieldName={'unitNo'}
        placeholder={t('RoomNumberFieldPlaceholder', { defaultValue: 'Room Number' })}
        onChange={onChange}
        validationRules={[isNumeric, maxLength(UNIT_NO_MAX_LENGTH)]}
        address={address}
        disableWhenHasValue={false}
      />
    </>
  )
}
