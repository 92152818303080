import { CountryToggle, FooterColumn } from '@dominos/components'
import { useBreakpoints, useDominosTheme, useFeaturesV2 } from '@dominos/hooks-and-hocs'
import React from 'react'
import { DebugInfo } from '../debug-info'
import { CookieSettings } from './cookie-settings'
import css from './footer.less'
import { LanguageSelector } from './language-selector'
import { TermsConditionsDisclaimers } from './terms-conditions-disclaimers'
import { useFooterTranslations } from './use-footer-translations'
import { useRestartOrder } from './use-restart-order'
import { DiagnosticsInfo } from '../diagnostics-info/diagnostics-info'

const dropdownHeight = 29

export const EmptyFooter = () => null
export const SimpleFooter = (props: FooterProps) => {
  const { hasRestart, testID, showTermsConditionsDisclaimers } = props
  const theme = useDominosTheme()
  const { isNotMobile } = useBreakpoints()
  const [footerContactUsEnabled, webDiagnosticsEnabled] = useFeaturesV2('footer-contact-us', 'WebDiagnostics')
  const translations = useFooterTranslations()
  const restartOrder = useRestartOrder()

  const onClickLegal = translations.legalUrl
    ? () => {
        window.open(translations.legalUrl)
      }
    : undefined

  return (
    <footer className={css.wrapper}>
      {showTermsConditionsDisclaimers && <TermsConditionsDisclaimers testID={testID} />}
      <div className={css.topContainer}>
        <div data-testid={`${testID}.bottom.container`} className={css.container}>
          <div data-testid={`${testID}.bottom.inner-container`} className={css.innerContainer}>
            <div data-testid={`${testID}.row`} className={isNotMobile ? css.desktopRow : css.row}>
              <FooterColumn testID={`${testID}.legal`} title={translations.legal} onClick={onClickLegal}>
                {translations.legalLinks.map((item) => {
                  if (item.title && item.link) {
                    return (
                      <a
                        key={item.title}
                        data-testid={`${testID}.legal-links.${item.title}`}
                        href={item.link}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {item.title}
                      </a>
                    )
                  }
                })}
                <CookieSettings testID={testID} />
              </FooterColumn>

              <FooterColumn testID={`${testID}.language`} title={translations.language}>
                <div style={{ marginTop: 5, width: 136 }}>
                  <LanguageSelector testID={testID} />
                </div>
              </FooterColumn>
            </div>

            <div data-testid={`${testID}.row`} className={isNotMobile ? css.desktopRow : css.row}>
              {footerContactUsEnabled && (
                <FooterColumn testID={`${testID}.call`} title={translations.contactUs}>
                  <a data-testid={`${testID}.contact-us`} href={`tel:${translations.phone.replace(' ', '')}`}>
                    <span
                      style={{ color: theme.colours.actionAlternative }}
                    >{`${translations.callText} ${translations.phone}`}</span>
                  </a>
                </FooterColumn>
              )}

              {hasRestart ? (
                <FooterColumn
                  testID={`${testID}.restart`}
                  title={translations.restartOrder}
                  onClick={restartOrder}
                  marginBottom={dropdownHeight}
                />
              ) : null}
            </div>
          </div>
          <CountryToggle
            DK={
              <div className={css.storeHealth}>
                {
                  <FooterColumn testID={`${testID}.storehealth`} title={translations.storeHealth}>
                    {
                      <a
                        href='https://www.findsmiley.dk/english/Pages/Search.aspx?k=40422668'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        {translations.storeHealthReport}
                      </a>
                    }
                  </FooterColumn>
                }
              </div>
            }
            FR={
              <div className={css.nutrition}>
                {
                  <FooterColumn testID={`${testID}.nutrition`} title={translations.nutritionalInformation}>
                    {
                      <a href='https://www.mangerbouger.fr/' target='_blank' rel='noopener noreferrer'>
                        {translations.nutritionalInformationText}
                      </a>
                    }
                  </FooterColumn>
                }
              </div>
            }
            default={<div />}
          />
        </div>
      </div>

      <DebugInfo />

      <div className={css.legal}>
        <p>{translations.copyRight}</p>
      </div>
      {webDiagnosticsEnabled && <DiagnosticsInfo />}
    </footer>
  )
}
