import { useTranslation } from 'react-i18next'
import { useFeaturesV2 } from '@dominos/hooks-and-hocs/features'
import { useLocalStorage } from '@dominos/hooks-and-hocs/browser-storage/use-local-storage'
import { useMemo, useState } from 'react'

const OnlyShowToolTipCount = 1
export const useDeliverySearchToolTip = () => {
  const { t, i18n } = useTranslation('delivery-address')
  const [deliverySearchToolTipEnabled] = useFeaturesV2('AutoCompleteDeliverySearchToolTip')
  const { storedValue, setValue } = useLocalStorage({
    key: 'delivery-search-tool-tip',
    defaultValue: { showCount: 0, dismissed: false, closedInSession: false },
  })
  const [hasClosedToolTip, setHasClosedToolTip] = useState<boolean>(false)

  const heading = t('DeliverySearchToolTipHeading')
  const message = t('DeliverySearchToolTipMessage')
  const buttonLabel = t('DeliverySearchToolTipButtonLabel')

  const imageDetails = useMemo(() => {
    const imageSrc = getDeliverySearchExampleImagesSrc(i18n.language)

    return imageSrc ? { alt: 'Delivery Search demonstration image', src: imageSrc } : undefined
  }, [i18n.language])

  const onClose = () => {
    setValue({
      showCount: storedValue.showCount + 1,
      dismissed: true,
      closedInSession: true,
    })
    setHasClosedToolTip(true)
  }

  const showToolTip = useMemo(
    () => !hasClosedToolTip && deliverySearchToolTipEnabled && storedValue.showCount < OnlyShowToolTipCount,
    [hasClosedToolTip, storedValue, deliverySearchToolTipEnabled],
  )

  return {
    showToolTip,
    onClose,
    heading,
    message,
    imageDetails,
    buttonLabel,
  }
}

export const getDeliverySearchExampleImagesSrc = (languageCode: string) => {
  switch (languageCode) {
    case 'ja-JP':
      return require('@dominos/res/assets/gifs/delivery-search/tool-tip-search-example/delivery-search-search-example-jp-ja_v2.gif')
    case 'en-JP':
      return require('@dominos/res/assets/gifs/delivery-search/tool-tip-search-example/delivery-search-search-example-jp-en_v2.gif')
    default:
      return undefined
  }
}
