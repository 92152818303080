import { getBasketLineDataForProduct, updateBasketLineData } from '@dominos/business/functions/basket'
import { DimensionFilter } from '@dominos/business/functions/menu/'
import {
  DimensionSetState,
  ProductData,
  usePricing,
  useProduct,
  useProductDimensionSetState,
  useReport,
} from '@dominos/hooks-and-hocs'
import React, { useEffect, useState } from 'react'
import { ProductContext } from './context'
import {
  getBasketLineDataForProductWithoutChanges,
  handlePortionChanges,
  mapProductContextToPriceProduct,
  transformDimensionSetStateToBasketLinePartial,
} from './functions'

export type ProductProviderProps = {
  children: React.ReactNode
  product: ProductMenuItem | PortionMenuItem
  basketLine?: BasketLine
  dimensionFilter?: DimensionFilter
}

const handleDimensionSetChanges = (
  dimensionSetState: DimensionSetState,
  productData: ProductData,
  basketLine: BasketLine | undefined,
) => {
  if (!dimensionSetState.selectedDimensionSet) {
    dimensionSetState.handleDimensionSetChange(productData.defaultDimensionSet)

    basketLine?.sizeCode && dimensionSetState.handleDimensionChange('Size', basketLine.sizeCode, true)
    basketLine?.base?.add && dimensionSetState.handleDimensionChange('Base', basketLine.base.add)
  }
}

const transformPortionsToBasketLinePartial = (portions: Portion[]) => ({
  sauce: undefined,
  toppings: undefined,
  portions: portions.map((portion) => ({
    productCode: portion.productCode!,
    media: undefined,
    sauce: portion.sauce,
    toppings: portion.toppings,
    options: undefined,
    surcharges: undefined,
  })),
})

const selectedDimensionSetChangeHandler = (
  dimensionSetState: DimensionSetState,
  productData: ProductData,
  isEditingFromBasket: boolean,
  basketLine: BasketLine | undefined,
  setPortions: (portions: Portion[] | undefined) => void,
  portions: Portion[] | undefined,
  firstPortionProductCode: string | undefined,
) => {
  if (!dimensionSetState.selectedDimensionSet || !productData) {
    return
  }

  if (productData.isPortionProduct && productData.defaultPortions?.length && !portions) {
    if (isEditingFromBasket && basketLine?.portions?.length) {
      handlePortionChanges(
        productData.defaultPortions,
        basketLine.portions.map((basketLinePortion: BasketLinePortion, index) => ({
          productCode: basketLinePortion.productCode ?? undefined,
          sauce: basketLinePortion.sauce,
          toppings: basketLinePortion.toppings,
          canSwap: productData.defaultPortions?.[index].canSwap ?? false,
          isSwapped: false,
        })),
        productData.getMaxPortionSwapCount(dimensionSetState.selectedDimensionSet),
        setPortions,
      )
    } else {
      const portions: Portion[] = productData.defaultPortions.map((portion: Bff.Products.Portion) => ({
        productCode: portion.defaultProductCode ?? undefined,
        canSwap: portion.canSwap,
        isSwapped: false,
      })) //  Set the first portion product code if this is the result of a switch to a portion product
      if (firstPortionProductCode) {
        portions[0].productCode = firstPortionProductCode
        portions[0].isSwapped = true
      }
      setPortions(portions)
    }
  }
}

export const ProductProvider = ({
  children,
  product: defaultProduct,
  basketLine,
  dimensionFilter,
}: ProductProviderProps) => {
  /** Track current basket line data changes for the current product code*/
  const [currentBasketLineData, setCurrentBasketLineData] = useState<BasketLineData>(
    getBasketLineDataForProduct(basketLine, defaultProduct as ProductMenuItem),
  )
  const [currentProductCode, setCurrentProductCode] = useState<string>(defaultProduct?.code ?? '')
  const [portions, setPortions] = useState<Portion[] | undefined>()
  const productData = useProduct(currentProductCode, dimensionFilter)
  const { reportPortionSwap } = useReport()
  const dimensionSetState = useProductDimensionSetState(
    productData.isDimensionSetValid,
    productData.getDimensionFilteredValues,
    productData.defaultDimensionSet,
  )
  const [firstPortionProductCode, setFirstPortionProductCode] = useState<string | undefined>()
  const { formattedPrice, formattedPromoPrice, getPriceUnconditionally } = usePricing()
  const isEditingFromBasket = !!basketLine?.itemNo
  useEffect(() => {
    if (!productData) {
      return
    }
    handleDimensionSetChanges(dimensionSetState, productData, basketLine)
  }, [productData, basketLine])

  useEffect(() => {
    selectedDimensionSetChangeHandler(
      dimensionSetState,
      productData,
      isEditingFromBasket,
      basketLine,
      setPortions,
      portions,
      firstPortionProductCode,
    )
  }, [dimensionSetState.selectedDimensionSet])

  const isValidForBasket = portions?.every((portion) => portion.productCode) ?? false

  useEffect(() => {
    if (isValidForBasket && portions && productData.isPortionProduct && dimensionSetState.selectedDimensionSet) {
      getPriceUnconditionally(
        mapProductContextToPriceProduct(currentProductCode, dimensionSetState.selectedDimensionSet, portions),
      )
    }
  }, [isValidForBasket, portions, dimensionSetState.selectedDimensionSet, currentProductCode])

  const handlePortionChange = (index: number, productCode: string | undefined) => {
    if (!portions || !productData.defaultPortions) {
      return
    }

    // Used in reporting, grab it before it's updated
    const previousProductCode = portions[index].productCode

    const desiredPortions = [...portions]
    desiredPortions[index] = {
      ...desiredPortions[index],
      productCode,
      sauce: undefined,
      toppings: undefined,
    }

    handlePortionChanges(
      productData.defaultPortions,
      desiredPortions,
      productData.getMaxPortionSwapCount(dimensionSetState.selectedDimensionSet),
      setPortions,
    )

    reportPortionSwap(currentProductCode, index, productCode, previousProductCode)
  }

  const handlePortionCustomisations = (
    index: number,
    key: 'sauce' | 'toppings',
    changes: BasketLineChange[] | BasketLineSwap | undefined,
  ) => {
    setPortions((prev) =>
      prev ? prev.map((portion, i) => (i === index ? { ...portion, [key]: changes } : portion)) : prev,
    )
  }

  const resetPortion = (portionIndex: number) => {
    handlePortionChange(portionIndex, productData.defaultPortions?.[portionIndex]?.defaultProductCode ?? undefined)
  }
  const createBasketLine = (quantity: number): BasketLine => ({
    ...currentBasketLineData,
    ...(portions ? transformPortionsToBasketLinePartial(portions) : { portions: undefined }),
    productCode: currentProductCode,
    ...transformDimensionSetStateToBasketLinePartial(productData.defaultDimensionSet, dimensionSetState),
    quantity,
    media: { name: defaultProduct?.media?.name || '' },
    totalPrice: null,
    itemNo: basketLine?.itemNo ?? 0,
    type: 'BasketLine',
    surcharges: basketLine?.surcharges,
  })

  const saveBasketLineDataChanges = (
    key: 'toppings' | 'sauce' | 'options',
    changes: BasketLineChange[] | BasketLineSwap | string | undefined,
  ) => updateBasketLineData(setCurrentBasketLineData, key, changes)

  const switchProduct = (code: string, firstPortionProductCode: string) => {
    setFirstPortionProductCode(firstPortionProductCode)
    setCurrentProductCode(code)
    dimensionSetState.handleDimensionSetChange(undefined)
    setPortions(undefined)
    setCurrentBasketLineData(getBasketLineDataForProductWithoutChanges(code, undefined))
  }

  return (
    <ProductContext.Provider
      value={{
        currentBasketLineData,
        productData,
        portions,
        dimensionSetState,
        initialQuantity: basketLine?.quantity || 1,
        formattedPrice,
        formattedPromoPrice,
        handlePortionChange,
        createBasketLine,
        saveBasketLineDataChanges,
        switchProduct,
        isValidForBasket,
        resetPortion,
        handlePortionCustomisations,
      }}
    >
      {children}
    </ProductContext.Provider>
  )
}
