import { useHasSalePrice } from '@dominos/hooks-and-hocs/pricing'
import { useHasPrice } from '@dominos/hooks-and-hocs/pricing'
import { useFeaturesV2 } from '@dominos/hooks-and-hocs/features'

export const useDisplayStrikeThroughPrice = ({
  price,
  salePrice,
  promoPrice,
}: {
  price?: string | null
  salePrice?: string | null
  promoPrice?: string | null
}) => {
  const hasSalePrice = useHasSalePrice({ salePrice })
  const hasPromoPrice = useHasPrice({ price: promoPrice })
  const [isStrikeThroughPricingEnabled] = useFeaturesV2('ShowStrikeThroughPricing')

  return {
    displayStrikeThroughPrice: hasSalePrice || (isStrikeThroughPricingEnabled && hasPromoPrice),
    alternatePrice: (hasSalePrice && salePrice) || (hasPromoPrice && promoPrice) || price,
  }
}
