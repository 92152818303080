import React from 'react'

import { getFormattedPrice } from '@dominos/business/functions/text/text-functions'
import { ActionButton, GenericSelect } from '@dominos/components'
import { useDominosTheme, useFeaturesV2 } from '@dominos/hooks-and-hocs'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import css from './styles/menu-customisation.less'

const SELECT_HEIGHT = '2rem'
const DEBOUNCE = 400

// eslint-disable-next-line max-lines-per-function
export const MenuCustomisation = ({
  testID,
  options: customisationOptions,
  onClick,
  menuCustomisationValues: currentMenuCustomisations,
  setSize,
  setBase,
  setOptions,
  setQuantity,
  inMenuCustomisation,
  extraSurcharge,
  showAddButton,
  hideQuantity,
}: MenuCustomisationProps) => {
  const { t } = useTranslation('menu')
  const {
    colours: { actionBlue, actionBlueBackgound, actionGreen, actionGreenBackgound, actionStandard, defaultButtonText },
  } = useDominosTheme()
  const [vspUpgradeButtonEnabled, leftNavMenuEnabled] = useFeaturesV2(
    'VoucherSoftPinningMenuUpgradeButton',
    'LeftNavMenu',
  )

  const clickHandler = () => {
    if (onClick) {
      onClick()
    }
  }

  const setCustomisation = (value: string, index: number) => {
    if (currentMenuCustomisations) {
      const customisationOption =
        customisationOptions[index].find((c) => c.value === value) || customisationOptions[index][0]
      switch (customisationOption?.type) {
        case 'size':
          setSize(value)
          break
        case 'base':
          setBase(value)
          break
        case 'option':
          setOptions(customisationOption)
          break
        case 'quantity':
          setQuantity(value)
          break
      }
    }
  }

  const getSelectedValue = (type: string | undefined, index?: number) => {
    if (type) {
      return currentMenuCustomisations?.find((item) => item.type === type && item.index === index)?.value
    }

    return undefined
  }

  return (
    <div aria-label='Menu Customisation' className={css.menuCustomisationContainer}>
      {customisationOptions.map((option, index) => {
        const onChange = (value: string) => setCustomisation(value, index)
        const isQuantity = option[0].type === 'quantity'
        const showOptions =
          isQuantity && hideQuantity
            ? false
            : showCustomisationOptions(currentMenuCustomisations, index, inMenuCustomisation)

        return (
          <div
            key={`${index}.div`}
            data-testid={`menu-customisation.selector.${testID}.${option[0].type}`}
            className={classnames(
              isQuantity && css.bottomButtonWrapper,
              isQuantity && leftNavMenuEnabled && css.leftNavMenu,
            )}
          >
            {showOptions ? (
              <GenericSelect
                key={index}
                testID={`${testID}-${option[0].type}`}
                data-testid={`${testID}-${option[0].type}`}
                options={option}
                selectedValue={getSelectedValue(option[0].type, option[0].index)}
                height={SELECT_HEIGHT}
                onChange={onChange}
                accessibilityLabel={`Select ${option[0].type}`}
              />
            ) : null}

            {isQuantity && inMenuCustomisation ? (
              <ActionButton
                debounce={DEBOUNCE}
                data-testid={`menu-customisation.${
                  showAddButton ? 'add' : !!extraSurcharge ? 'upgrade' : 'select'
                }.${testID}`}
                testID={testID}
                text={
                  showAddButton
                    ? t('ADD')
                    : !!extraSurcharge
                    ? vspUpgradeButtonEnabled
                      ? `+${getFormattedPrice(extraSurcharge)}`
                      : t('Upgrade')
                    : t('Select')
                }
                onPress={clickHandler}
                textFontWeight='bold'
                containerStyle={{
                  minHeight: 30,
                  padding: 0,
                  backgroundColor: showAddButton
                    ? actionGreenBackgound
                    : !!extraSurcharge
                    ? vspUpgradeButtonEnabled
                      ? actionBlueBackgound
                      : actionBlue
                    : vspUpgradeButtonEnabled
                    ? actionGreenBackgound
                    : actionStandard,
                }}
                textStyle={{
                  fontSize: leftNavMenuEnabled ? '0.75rem' : '1rem',
                  color: showAddButton
                    ? actionGreen
                    : !vspUpgradeButtonEnabled
                    ? defaultButtonText
                    : !!extraSurcharge
                    ? actionBlue
                    : actionGreen,
                }}
              />
            ) : null}
          </div>
        )
      })}
      {!inMenuCustomisation ? (
        <ActionButton
          debounce={DEBOUNCE}
          data-testid={`menu-customisation.select.${testID}`}
          testID={testID}
          text={t('Select')}
          onPress={clickHandler}
          textFontWeight={'semibold'}
          className={classnames(css.menuSelectButton)}
          containerStyle={{
            width: '-webkit-fill-available',
            minHeight: 30,
            padding: 0,
            backgroundColor: actionGreenBackgound,
          }}
          textStyle={{
            fontSize: leftNavMenuEnabled ? '0.75rem' : '1rem',
            color: actionGreen,
          }}
        />
      ) : null}
    </div>
  )
}

const showCustomisationOptions = (
  currentMenuCustomisations: MenuCustomisationItem[] | undefined,
  optionsIndex: number,
  inMenuCustomisation?: boolean | null,
): boolean => {
  if (!inMenuCustomisation) {
    return false
  }

  const currentOption = currentMenuCustomisations?.[optionsIndex]

  return currentOption?.label !== '' && currentOption?.label !== null && currentOption?.label !== undefined
}
