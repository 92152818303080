/* eslint-disable max-lines */
import React, { useMemo } from 'react'

import { useFeaturesV2 } from '@dominos/hooks-and-hocs'
import { AddressSearchErrors } from '@dominos/components'
import { AddressValidationResult } from '@dominos/hooks-and-hocs/location/use-validate-address'

import { ConfirmAddress } from './confirm-address'

import css from '../delivery-address.less'

interface ConfirmAddressContainerProps {
  testID: string
  address?: DeliveryAddressRequest
  errorCode?: string
  isLoading: boolean
  searchMode: 'List' | 'Map'
  addressValidationResult: AddressValidationResult
  onAddressConfirm: () => void
  onAddressClear: () => void
  onAddressCorrection: (input: AdditionalFields) => void
}

const DELIVERY_SERVICE_METHOD = 'Delivery'

export const ConfirmAddressContainer = (props: ConfirmAddressContainerProps) => {
  const { testID, address, errorCode, isLoading, searchMode, onAddressConfirm, onAddressClear } = props
  const [autoCompleteDeliveryAddressUserManualAddressEntry] = useFeaturesV2(
    'AutoCompleteDeliveryAddressUserManualAddressEntry',
  )

  const confirmAddressStyles = searchMode === 'Map' ? css.confirmAddressContainerMap : css.confirmAddressContainerList

  const errorStyles = searchMode === 'Map' ? css.confirmAddressContainerMapError : css.confirmAddressContainerListError

  const fieldsForManualCorrection = useMemo(() => {
    if (!props.addressValidationResult || !props.addressValidationResult.invalidFields) return []

    return props.addressValidationResult.invalidFields.filter((field) => field.rules.includes('manual'))
  }, [props.addressValidationResult.invalidFields])

  if (!errorCode && !address) return null

  if (errorCode && !isLoading)
    return (
      <div className={errorStyles}>
        <div style={{ padding: 8 }}>
          <AddressSearchErrors
            testID={`${testID}.errors`}
            errorCode={errorCode}
            isLoading={isLoading}
            serviceMethod={DELIVERY_SERVICE_METHOD}
            onRetry={onAddressConfirm}
          />
        </div>
      </div>
    )

  if (
    autoCompleteDeliveryAddressUserManualAddressEntry &&
    address &&
    fieldsForManualCorrection &&
    fieldsForManualCorrection.length > 0
  ) {
    return (
      <div className={confirmAddressStyles}>
        <ConfirmAddress
          testID={`${testID}.confirm-address-missing-address-attributes`}
          address={address}
          loading={isLoading}
          invalidFields={fieldsForManualCorrection}
          addressIsValid={props.addressValidationResult.isValid}
          onChange={props.onAddressCorrection}
          onConfirm={onAddressConfirm}
          onTriggerClick={onAddressClear}
          userCorrection
        />
      </div>
    )
  }

  if (!address || searchMode === 'List') return null

  return (
    <div className={confirmAddressStyles}>
      <ConfirmAddress
        testID={`${testID}.confirm-address-map`}
        address={address}
        onConfirm={onAddressConfirm}
        onTriggerClick={onAddressClear}
        loading={isLoading}
      />
    </div>
  )
}
