/* eslint-disable max-lines */
import {
  BasketLineData,
  createBasketLine,
  getBasketLineData,
  updateBasketLineData,
} from '@dominos/business/functions/basket'
import { flattenBasketOptions, getImages } from '@dominos/business/functions/menu'
import {
  getHalfNHalfLineSauceChangeCode,
  getHalfNHalfPresetSauceCode,
  getHalfNHalfSharedSauces,
} from '@dominos/business/functions/product/get-half-n-half-sauces'
import {
  BottomBar,
  HalfHalfButtonCard,
  HalfHeader,
  isHalfNHalfEnabled,
  isPortionMenuItem,
  isProductMenuItem,
  isProductMenuItemNew,
  Legends,
  ProductContainer,
  ProductDetailsCard,
  ProductNutritionalCard,
  ProductTermsAndConditions,
  useProductContext,
} from '@dominos/components'
import BackButton from '@dominos/components/product/product-card-closer/back-button'
import CloseButton from '@dominos/components/product/product-card-closer/close-button'
import {
  useBreakpoints,
  useDevToggles,
  useDominosTheme,
  useFeaturesV2,
  useHalfNHalf,
  useKiosk,
  useMenu,
  useOptionChanges,
  usePricing,
  useReport,
} from '@dominos/hooks-and-hocs'
import { motion, useSpring, useTransform, useViewportScroll } from 'framer-motion'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CustomisationDefault, CustomisationHalfNHalfSide } from './customisations'
import {
  createStyles,
  getCurrentProduct,
  getCustomisationDifference,
  getProductSetter,
  getProductSize,
  validateProductBase,
} from './functions'
import css from './product-card.less'
import { ComboUpsellContainer } from './combo-upsell/combo-upsell-container'
import { isNativeApp } from '@dominos/business/functions/native-app'
import ProductBuildYourOwn from '../product-build-your-own/product-build-your-own'
import { ProductUpsell } from '../product-upsell/product-upsell'
import {
  ProductComponentUpsellLDVariation,
  ProductUpsellComponentType,
  ProductUpsellVariationTypes,
} from '@dominos/components/product/product-upsell/product-upsell.interface'
import { productChangesTransform } from '@dominos/business/functions'

type ProductMenuItem = Bff.Menu.old.ProductMenuItem
type ProductSize = Bff.Menu.old.ProductSize
type MenuLegend = Bff.Menu.old.MenuLegend
type PortionSize = Bff.Menu.old.PortionSize

const MARGIN_OFFSET = 50
const IMAGE_HEIGHT = window.innerWidth * 0.6
const IGNORE = ['kJ.Pizza', 'kJ.Standard']

const getLegends = (item: MenuItemDependents) =>
  'legends' in item ? (item.legends || []).filter((legend) => !IGNORE.includes(legend.code)) : []

// eslint-disable-next-line max-lines-per-function
export const ProductCard = ({ testID, item, addToBasket, lineData, isEditing, onDismiss }: ProductCardProps) => {
  const { reportCreateHalfHalf } = useReport()
  const theme = useDominosTheme()
  const { isMobile } = useBreakpoints()
  const { isKioskOrder } = useKiosk()
  const ref = useRef<HTMLImageElement>(null)
  const { itemsByCode } = useMenu()
  const { t } = useTranslation('menu')
  const { formattedPrice, formattedPromoPrice, getPrice } = usePricing()
  const [
    restrictedHalfHalfEnabled,
    productHalfHalfEnabled,
    productBuildYourOwnEnabled,
    productComponentUpsellVariation,
  ] = useFeaturesV2(
    'RestrictedHalfHalf',
    'product-half-half',
    'ProductBuildYourOwnEnabled',
    'ProductComponentUpsellVariation',
  )
  const isProductUpsellEnabled = productComponentUpsellVariation !== ProductComponentUpsellLDVariation.VariationNone

  const { saveBasketLineDataChanges, switchProduct, dimensionSetState } = useProductContext()
  const { isEnabled } = useDevToggles()

  const isMobileLayout = isMobile || isKioskOrder

  useEffect(() => setImageHeight((ref.current && ref.current.height) || IMAGE_HEIGHT), [isMobileLayout])

  // Mobile View - image scroll fade
  const [imageHeight, setImageHeight] = useState<number>(IMAGE_HEIGHT)

  const { scrollY } = useViewportScroll()
  const yRange = useTransform(scrollY, [imageHeight - MARGIN_OFFSET, 0], [0, 1])
  const opacity = useSpring(yRange, { stiffness: 400, damping: 40 })
  const [product, setProduct] = useState<BasketLineData>(getBasketLineData(itemsByCode, lineData, undefined, item))
  const { halfAndHalfItem, halfRight, halfLeft, setHalf, setHalfProduct } = useHalfNHalf(
    item as ProductMenuItem,
    lineData,
    isEditing,
  )
  const [currentPortionSide, setCurrentPortionSide] = useState<HalfSides | undefined>()
  const currentProduct = getCurrentProduct(product, halfLeft, halfRight, currentPortionSide)
  const currentSize = useMemo(
    () => getProductSize(currentProduct?.item, product.sizeCode ?? lineData?.sizeCode),
    [product.sizeCode, currentProduct?.item, lineData?.sizeCode],
  )

  const [triggerPresetSauce, setTriggerPresetSauce] = useState<boolean>(false)

  const flattedChangedOptions = flattenBasketOptions(product.options)

  const [minOptionsSelected, setOptionAsSelected] = useOptionChanges(
    currentSize?.swaps?.options?.rule,
    currentSize?.recipe?.options,
    flattedChangedOptions,
  )

  useEffect(() => {
    if (restrictedHalfHalfEnabled && halfLeft?.item && halfRight?.item) {
      const sauces = getHalfNHalfSharedSauces(
        currentSize?.code ?? undefined,
        halfLeft?.item as ChangeableProductItem,
        halfRight?.item as ChangeableProductItem,
      )
      if (!sauces?.length) {
        // unset sauce change
        setProductAttribute('sauce', undefined)
        setProductAttribute('sauce', undefined, 'left')
        setProductAttribute('sauce', undefined, 'right')
        saveBasketLineDataChanges('sauce', undefined)
      } else {
        const sauceCode =
          getHalfNHalfLineSauceChangeCode(product, currentSize?.code, sauces, halfLeft) ??
          getHalfNHalfPresetSauceCode(
            currentSize?.code,
            halfLeft?.item as ChangeableProductItem,
            halfRight?.item as ChangeableProductItem,
            sauces,
          )
        const sauce = sauces.find((s) => s.code === sauceCode)
        if (sauce) {
          onSauceSelected({
            code: sauce.code,
            name: sauce.media?.name ?? '',
          })
        }
      }
    }
  }, [triggerPresetSauce])

  useEffect(() => {
    if (!!product.item && isPortion) getPrice(product, halfLeft, halfRight)
  }, [product, halfLeft, halfRight])

  if (!currentProduct?.item) {
    return null
  }

  const isProduct = isProductMenuItem(currentProduct?.item)
  const isPortion = isPortionMenuItem(currentProduct?.item)
  const isHalfNHalfSideProduct = isProduct && !!currentPortionSide

  const setProductAttribute = (
    key: 'item' | 'sizeCode' | 'base' | 'toppings' | 'sauce' | 'options',
    change: ProductMenuItem | BasketLineChange[] | BasketLineSwap | string | undefined,
    side?: HalfSides,
  ) => {
    const currentProductSetter = getProductSetter(
      setProduct,
      setHalf('left'),
      setHalf('right'),
      side ?? currentPortionSide,
    )
    updateBasketLineData(currentProductSetter, key, change)
  }

  const onSizeSelected = (change: TouchableGridElement) => {
    const itemSizes = (item as ChangeableProductItem).sizes
    const previousSize = itemSizes?.find((size: ProductSize) => size.code === product?.sizeCode)
    const foundSize = itemSizes?.find((size: ProductSize) => size.code === change.code)
    if (!!foundSize) {
      const defaultCrust = foundSize.recipe?.base?.code
      const crusts = [...(foundSize.swaps?.bases?.ingredients ?? [])]

      const selectedCrust = product?.base?.add || previousSize?.recipe?.base?.code || defaultCrust

      setProductAttribute('sizeCode', foundSize.code as string)
      const baseDiff = validateProductBase(crusts, defaultCrust, selectedCrust)
      setProductAttribute('base', baseDiff)

      dimensionSetState?.handleDimensionChange('Size', foundSize.code as string, true)
    }

    if (restrictedHalfHalfEnabled) {
      setTriggerPresetSauce(!triggerPresetSauce)
    }
  }

  const onIngredientSelected = (changes: BasketLineChange[]) => {
    setProductAttribute('toppings', changes)
    saveBasketLineDataChanges('toppings', changes)
  }

  const onRecipeSelected = (key: 'sauce' | 'base' | 'options') => (change: TouchableGridElement) => {
    const prod = currentPortionSide ? (currentPortionSide === 'left' ? halfLeft : halfRight) : product
    const diff = getCustomisationDifference(key, change, currentSize, prod)

    if (key === 'options') {
      setOptionAsSelected(change.index, change.code)
    }
    if (key == 'base') {
      dimensionSetState?.handleDimensionChange('Base', change.code as string)
    } else {
      saveBasketLineDataChanges(key, diff)
    }

    return setProductAttribute(key, diff)
  }

  const onSauceSelected = (sauce: TouchableGridItem) => {
    if (!product?.sauce || product?.sauce?.add !== sauce.code) {
      const diff = {
        add: sauce.code,
        media: {
          add: sauce.code,
          remove: product?.sauce?.add,
        } as BasketLineSwapMedia,
        remove: product?.sauce?.add,
      } as BasketLineSwap
      setProductAttribute('sauce', diff)
      saveBasketLineDataChanges('sauce', diff)
    }

    const sizeLeft = getProductSize(halfLeft?.item as MenuItemDependents, halfLeft?.sizeCode ?? undefined)
    const diffLeft = getCustomisationDifference('sauce', sauce, sizeLeft, halfLeft)
    setProductAttribute('sauce', diffLeft, 'left')

    const sizeRight = getProductSize(halfRight?.item as MenuItemDependents, halfRight?.sizeCode ?? undefined)
    const diffRight = getCustomisationDifference('sauce', sauce, sizeRight, halfRight)
    setProductAttribute('sauce', diffRight, 'right')
  }

  const setHalfSideProduct = (side: HalfSides) => (prod?: ProductMenuItem) => {
    setHalfProduct(side)(prod)
    if (restrictedHalfHalfEnabled) {
      setTriggerPresetSauce(!triggerPresetSauce)
    }
  }

  const handleUpsellSelected = (change: TouchableGridElement, componentType: string | null) => {
    switch (componentType) {
      case ProductUpsellComponentType.Crust:
        onRecipeSelected('base')(change)
        break
      case ProductUpsellComponentType.Sauce:
        onRecipeSelected('sauce')(change)
        break
      case ProductUpsellComponentType.Topping:
        const isRecipeIngredient =
          currentSize?.recipe?.toppings?.some((topping) => topping?.ingredient.code === change.code) || false
        const toppings = productChangesTransform(
          1,
          change.code,
          change.name,
          isRecipeIngredient,
          currentProduct?.toppings,
        )
        onIngredientSelected(toppings)
        break
      default:
        break
    }
  }

  const showHalfNHalfButton = () =>
    !!(
      productHalfHalfEnabled &&
      isHalfNHalfEnabled(currentProduct.item as MenuItemDependents) &&
      !isPortion &&
      !currentPortionSide &&
      halfAndHalfItem
    )

  const onHalfHalfButtonClicked = () => {
    const halfAndHalfProduct = getBasketLineData(itemsByCode, product, undefined, halfAndHalfItem as MenuItemDependents)
    setProduct(halfAndHalfProduct)
    const productCode =
      halfAndHalfProduct.item && !isProductMenuItemNew(halfAndHalfProduct.item) ? halfAndHalfProduct.item?.code : ''
    const previousCode =
      currentProduct.item && !isProductMenuItemNew(currentProduct.item) ? currentProduct.item?.code : ''
    switchProduct(productCode, previousCode)

    reportCreateHalfHalf(previousCode, productCode)

    if (isEditing) {
      setHalf('right')(() => ({ item: undefined }))
    }
  }
  const savePortionProduct = (quantity: number) => setCurrentPortionSide(undefined)

  const addToBasketHandler = (quantity: number) => {
    const value = createBasketLine(quantity, product, halfLeft, halfRight)
    if (addToBasket && value) {
      addToBasket(value)
    }
    onDismiss(true)
  }

  const dismissProductCard = () => onDismiss(false)

  const legends: MenuLegend[] = getLegends(currentProduct.item)

  const url = currentProduct ? getImages(currentProduct.item).productImage.fullUrl : ''
  const styles = createStyles(theme, isMobileLayout, isPortion)

  return (
    <ProductContainer testID={testID}>
      {!isPortion && (
        <motion.img
          style={isMobileLayout ? { opacity } : { opacity: 1 }}
          ref={ref}
          data-testid={`${testID}.image`}
          className={css.image}
          src={url}
          alt=''
        />
      )}

      {!currentPortionSide &&
        !isNativeApp() &&
        (isMobileLayout ? (
          <BackButton testID={testID} onClick={dismissProductCard} />
        ) : (
          <CloseButton testID={testID} onClick={dismissProductCard} />
        ))}

      {isPortion && (
        <HalfHeader
          testID={`${testID}.product`}
          items={itemsByCode}
          size={currentSize as PortionSize}
          halfLeft={halfLeft.item as ProductMenuItem}
          halfRight={halfRight.item as ProductMenuItem}
          isMobileLayout={isMobileLayout}
          formattedPrice={formattedPrice}
          formattedPromoPrice={formattedPromoPrice}
          onHalfProductSet={setHalfSideProduct}
          onHalfCustomize={setCurrentPortionSide}
        />
      )}

      <div data-testid={`${testID}.children`} className={css.modalChildren} style={styles.modalChildren}>
        {!isPortion && legends.length >= 1 && (
          <Legends
            testID={'MenuLegend'}
            containerStyle={styles.legend}
            imageStyle={styles.legendImage}
            legends={legends}
          />
        )}

        {!isPortion && <ProductDetailsCard testID={`${testID}.details-card`} product={currentProduct} />}
        {showHalfNHalfButton() &&
          (productBuildYourOwnEnabled ? (
            <ProductBuildYourOwn
              t={t}
              color={theme.colours.actionBlue}
              testID={`${testID}.product-build-your-own`}
              onClick={onHalfHalfButtonClicked}
            />
          ) : (
            <HalfHalfButtonCard
              buttonTitle={t('CreateHalfHalfButton')}
              testID={`${testID}.half-half-button-card`}
              onClick={onHalfHalfButtonClicked}
            />
          ))}

        {(isProduct || isPortion) && (
          <>
            {isHalfNHalfSideProduct ? (
              <CustomisationHalfNHalfSide
                currentProduct={currentProduct}
                onRecipeSelected={onRecipeSelected}
                onIngredientSelected={onIngredientSelected}
                currentSize={currentSize}
              />
            ) : (
              item &&
              !isProductMenuItemNew(item) && (
                <CustomisationDefault
                  currentProduct={currentProduct}
                  onSizeSelected={onSizeSelected}
                  onRecipeSelected={onRecipeSelected}
                  onIngredientSelected={onIngredientSelected}
                  onSauceSelected={onSauceSelected}
                  currentSize={currentSize}
                  item={item}
                  restrictedHalfHalfEnabled={restrictedHalfHalfEnabled}
                  halfLeft={halfLeft}
                  halfRight={halfRight}
                  onUpsellSelected={handleUpsellSelected}
                />
              )
            )}
          </>
        )}

        {isEnabled['combo-upsell'] && (
          <ComboUpsellContainer testID={`product-card.combo-upsell`} item={currentProduct.item as ProductMenuItem} />
        )}

        {!currentPortionSide && (
          <ProductNutritionalCard testID={`product-card.nutritional-card`} currentProduct={currentProduct} />
        )}
        <ProductTermsAndConditions />
      </div>

      {isProductUpsellEnabled && isProduct && (
        <ProductUpsell
          currentProduct={currentProduct}
          productUpsellVariation={ProductUpsellVariationTypes.VariationPopup}
          onUpsellSelected={handleUpsellSelected}
        />
      )}

      <BottomBar
        testID={`${testID}.bottom-bar`}
        onComplete={!currentPortionSide ? addToBasketHandler : savePortionProduct}
        isEditing={isEditing}
        initialQuantity={lineData && lineData.quantity}
        isPortionProduct={!!currentPortionSide}
        forceDisabled={(isPortion && (!halfLeft.item || !halfRight.item)) || !minOptionsSelected}
      />
    </ProductContainer>
  )
}
