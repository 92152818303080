import { useCurrentOrderDetails, useFeaturesV2 } from '@dominos/hooks-and-hocs'
import { RouteComponentProps, useLocation } from '@reach/router'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { isNativeApp } from '@dominos/business/functions'
import { NativeAppFosData } from '@dominos/interfaces'
import { rootActions } from '@dominos/business'

export const FosSessionImport: React.FC<RouteComponentProps> = ({ children }) => {
  const location = useLocation()
  const { advanceOrder } = useCurrentOrderDetails()
  const [isFosFeatureEnabled] = useFeaturesV2('IsFosVersionThreeEnabled')
  const dispatch = useDispatch()
  const nativeAppFosData = window.nativeAppFosData as NativeAppFosData

  useEffect(() => {
    if (isNativeApp() && nativeAppFosData && advanceOrder.id !== nativeAppFosData?.id && isFosFeatureEnabled) {
      dispatch(rootActions.importFosSessionFromNativeApp(nativeAppFosData))
    }
  }, [location, isFosFeatureEnabled])

  return <>{children}</>
}
