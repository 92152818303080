import { ApolloProvider } from '@apollo/client'
import { apolloClient } from '@dominos/apollo'
import { getCountryConfig, initAppData } from '@dominos/business/functions'
import { SplashScreen } from '@dominos/components'
import {
  ApplicationProvider,
  AuthenticationProvider,
  B2BConfigProvider,
  ConfigProvider,
  DevTogglesProvider,
  FeatureProvider,
  LaunchDarklyProvider,
  MenuProvider,
  OneTrustProvider,
  QuickUniversalAccountComponentProvider,
  SecurityProvider,
  SwrveProvider,
  useDevToggles,
  useIdentifyContext,
  useLaunchDarklyFlags,
  useOneTrust,
  useVendorsContext,
  VendorsProvider,
  VirtualKeyboardProvider,
  WithPersistentStore,
} from '@dominos/hooks-and-hocs'
import OrderGuard from '@dominos/hooks-and-hocs/order/order-guard'
import { IdleTimer, PollingTimer } from '@dominos/hooks-and-hocs/session'
import { Routes } from '@dominos/navigation'
import '@dominos/res/apple-pay/apple-developer-merchantid-domain-association.txt'
import '@dominos/res/mobile-app-deep-link-support/android/assetlinks.json'
import '@dominos/res/mobile-app-deep-link-support/apple/apple-app-site-association.json'
import { graphql, setupWorker } from 'msw'
import React, { Suspense, useMemo } from 'react'
import { render } from 'react-dom'
import { hot } from 'react-hot-loader/root'
import { useSelector } from 'react-redux'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import '../style.less'
import './style.css'

import { handlePushNotificationConfig } from '@dominos/business/functions/swrve'
import { AuthProviderB2B } from '@dominos/components/login-b2b/AuthProviderB2B'
import { useOrderValidation } from '@dominos/hooks-and-hocs/validation/use-order-validation'
import { AddressProvider } from '@dominos/hooks-and-hocs/address/providers'

/* Important: this needs to be called before the app is rendered,
  and before setupVendors is called.
  When refactoring this file, don't move this down.
*/
initAppData()

declare global {
  interface Window {
    __olo_web_app_version__: string
    __idle_timer__?: IdleTimer
    __polling_timer__?: PollingTimer
    _ROKT_: string
    rokt?: Rokt.Instance
    roktClient?: Rokt.Client
    _RECAPTCHA_: string
    _GTM_?: string
    _APPLEID_: string
    _SNOWPLOW_: string
  }
}

const App = () => (
  <ConfigProvider>
    <DevTogglesProvider>
      <LaunchDarklyProvider>
        <VendorsProvider>
          <WithPersistentStore>
            <Suspense fallback={<SplashScreen testID='splash-screen-app' />}>
              <InnerApp />
            </Suspense>
          </WithPersistentStore>
        </VendorsProvider>
      </LaunchDarklyProvider>
    </DevTogglesProvider>
  </ConfigProvider>
)

const InnerApp = () => {
  const settings = useSelector((state: RootReducer) => state.applicationReducer)
  const sessionID = useSelector((state: RootReducer) => state.sessionReducer.sessionID)
  const { flags } = useLaunchDarklyFlags()
  const { isEnabled } = useDevToggles()
  const { sendSecurity } = useVendorsContext()
  const isPersistedQueryEnabled = isEnabled['persisted-query']
  const isUseGETForHashedQueriesEnabled = isEnabled['use-get-for-persisted-query']

  // Hack to reset redux data if old or inconsistent. Remove once redux state is better managed
  useOrderValidation()

  useOneTrust()
  useIdentifyContext()

  const client = useMemo(
    () =>
      settings.countryCode && settings.language && settings.graphUrl
        ? apolloClient(
            {
              sessionID,
              language: settings.language,
              countryCode: settings.countryCode,
              url: settings.graphUrl,
              application: settings.application,
              version: settings.version,
              applicationVersion: settings.applicationVersion,
              pushNotificationClientId: handlePushNotificationConfig(settings.webPushNotificationClientId),
            },
            sendSecurity,
            flags,
            {
              isPersistedQueryEnabled,
              isUseGETForHashedQueriesEnabled,
            },
          )
        : null,
    [
      sessionID,
      settings.language,
      settings.countryCode,
      settings.graphUrl,
      settings.application,
      settings.version,
      settings.webPushNotificationClientId,
      isPersistedQueryEnabled,
      isUseGETForHashedQueriesEnabled,
    ],
  )

  if (!client) {
    return null
  }

  return (
    <ApolloProvider client={client}>
      <ContextProviders>
        <OrderGuard>
          <Routes />
        </OrderGuard>
      </ContextProviders>
    </ApolloProvider>
  )
}
const TIMEOUT_FOR_FEATURES_CALL = 500
const ContextProviders: React.FC = ({ children }) => (
  <ApplicationProvider>
    <OneTrustProvider>
      <FeatureProvider timeoutTime={TIMEOUT_FOR_FEATURES_CALL}>
        <SecurityProvider>
          <SwrveProvider>
            <AuthenticationProvider>
              <QuickUniversalAccountComponentProvider>
                <AuthProviderB2B>
                  <B2BConfigProvider>
                    <VirtualKeyboardProvider>
                      <AddressProvider>
                        <MenuProvider>{children}</MenuProvider>
                      </AddressProvider>
                    </VirtualKeyboardProvider>
                  </B2BConfigProvider>
                </AuthProviderB2B>
              </QuickUniversalAccountComponentProvider>
            </AuthenticationProvider>
          </SwrveProvider>
        </SecurityProvider>
      </FeatureProvider>
    </OneTrustProvider>
  </ApplicationProvider>
)

if (__MOCK__) {
  const { COUNTRY } = getCountryConfig()
  const countryCode = COUNTRY.toLowerCase() || 'au'

  const queries = ['menu', 'storeSearchQuery']
  const queryHandlers = queries.map((request) => {
    require(`../mocks/fixtures/queries/${countryCode}/${request}.json`)

    return graphql.query(request, async (req, res, ctx) => {
      const response = await fetch(`/mocks/fixtures/queries/${countryCode}/${request}.json`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })

      const menu = await response.json()

      return res(ctx.data(menu.data))
    })
  })

  const worker = setupWorker(...queryHandlers)
  worker.start()
}

render(React.createElement(hot(App)), document.getElementById('app'))
