import { useFeaturesV2 } from '@dominos/hooks-and-hocs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { createProductTermsAndConditionsViewModel } from './create-product-terms-and-conditions-view-model'

import {
  ProductTermsAndConditionsView,
  ProductTermsAndConditionsViewContent,
  ProductTermsAndConditionsViewContentWrapper,
} from '@dominos/components/product'

export const ProductTermsAndConditions = (): JSX.Element => {
  const { t } = useTranslation('menu')
  const [isProductTermsEnabled] = useFeaturesV2('ProductTerms')
  const viewModel = createProductTermsAndConditionsViewModel({
    t,
    isFeatureEnabled: isProductTermsEnabled,
  })

  return (
    <ProductTermsAndConditionsView
      testID='product.terms-and-conditions'
      viewModel={viewModel}
      TermsAndConditionsViewContent={ProductTermsAndConditionsViewContent}
      TermsAndConditionsViewContentWrapper={ProductTermsAndConditionsViewContentWrapper}
    />
  )
}
