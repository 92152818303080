import { navigate, useLocation } from '@reach/router'
import { useDispatch } from 'react-redux'
import { rootActions } from '@dominos/business'
import { deleteVWOCookies } from '@dominos/business/functions'
import { useFos, useReport } from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation'
import { FosEventInfoType, FosInteractionEventType } from 'olo-feature-fos'

export const useRestartOrder = ({
  report = true,
  fos = true,
  deleteVwoCookies = false,
}: { report?: boolean; fos?: boolean; deleteVwoCookies?: boolean } = {}) => {
  const { reportOrderReset } = useReport()
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const { sendFosEvent } = useFos()

  return () => {
    dispatch(rootActions.restartOrder())
    if (report) reportOrderReset()
    if (fos)
      sendFosEvent({
        type: FosEventInfoType.InteractionEvent,
        locationPath: pathname,
        eventType: FosInteractionEventType.OrderRestarted,
      })
    if (deleteVwoCookies) {
      deleteVWOCookies()
      // setTimeout is used to ensure that redux persist saves the new state before the page is reloaded
      setTimeout(() => {
        window.location.href = NavigationConstants.home
      }, 500)
    } else {
      navigate(NavigationConstants.home)
    }
  }
}
