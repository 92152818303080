import { getCountryConfig } from '@dominos/business/functions/common/get-config'
import {
  getPrecisionFixedValue,
  getRoundUpAlgorithm,
  useRoundUpForCharityDetails,
} from '@dominos/components/charity/round-up'

import { useBasketTotal, useFeaturesV2, useTipAmount } from '@dominos/hooks-and-hocs'
import { CountryToggle } from '@dominos/components/toggles'
import { roundUpConfig } from './round-up-config'
import { useMemo } from 'react'

export const usePaymentAmount = (
  donationEnabled: boolean = false,
  outstandingBalance: number = 0,
  paymentLimit: number = 0,
) => {
  const basketTotal = useBasketTotal()
  const tipAmount = useTipAmount()
  const roundUpForCharityDetails = useRoundUpForCharityDetails()
  const [adyenGivingEnabled] = useFeaturesV2('AdyenGiving')
  const withDonation = donationEnabled && !!roundUpForCharityDetails
  const isLegacyRoundUp = !adyenGivingEnabled && !!roundUpForCharityDetails
  const unit = CountryToggle(roundUpConfig)
  const config = getCountryConfig()

  const getRoundUp = getRoundUpAlgorithm(unit)

  const getPaymentAmount = (outstandingBalance: number, basketTotal: number, paymentLimit: number) => {
    if (outstandingBalance > 0) {
      // payment methods with payment limit should not support donation.
      if (paymentLimit > 0 && paymentLimit < outstandingBalance) {
        return paymentLimit
      } else {
        return outstandingBalance + (isLegacyRoundUp ? roundUpForCharityDetails.amount! : 0)
      }
    } else {
      if (paymentLimit > 0 && paymentLimit < basketTotal) {
        return paymentLimit
      } else {
        return basketTotal
      }
    }
  }

  const getTotalAmount = (withDonation: boolean, total: number) =>
    withDonation ? getPrecisionFixedValue(total + roundUpForCharityDetails!.amount)! : total!

  const paymentAmount = useMemo(
    () => getPaymentAmount(outstandingBalance, basketTotal!, paymentLimit!),
    [outstandingBalance, basketTotal, paymentLimit, roundUpForCharityDetails],
  )

  // payment limit > 0 means the payment method not support donation
  const totalAmount = useMemo(
    () => (paymentAmount ? getTotalAmount(paymentLimit! > 0 ? false : withDonation, paymentAmount) : 0),
    [withDonation, paymentAmount, roundUpForCharityDetails, paymentLimit],
  )

  const displayAmount = useMemo(() => getPrecisionFixedValue(totalAmount + tipAmount), [totalAmount, tipAmount])

  const roundUpAmount = useMemo(() => (basketTotal! ? getRoundUp(basketTotal) : 0), [basketTotal])

  const adyenAmount = useMemo(
    () => parseInt((config.CURRENCY_CODE === 'JPY' ? displayAmount! : displayAmount! * 100).toPrecision(10)),
    [displayAmount],
  )

  return {
    adyenAmount,
    roundUpAmount,
    paymentAmount,
    displayAmount,
  }
}
