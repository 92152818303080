import { LDProvider, ProviderConfig } from 'launchdarkly-react-client-sdk'
import React from 'react'
import { applicationName } from '@dominos/business/functions'
import { useConfigContext } from '@dominos/hooks-and-hocs'
import { getDefaultLanguage } from '@dominos/plugins/helpers/get-default-language'
import { LDClientContext } from './use-identify-context'

interface LaunchDarklyStartupProps extends PropsWithChildren {
  launchDarklyID: string
}

export const LaunchDarklyStartup = ({ children, launchDarklyID }: LaunchDarklyStartupProps) => {
  const { applicationConfig } = useConfigContext()

  const getContext = (): LDClientContext => {
    const defaultLanguage = getDefaultLanguage(applicationConfig.LANGUAGES.split(','), applicationConfig.LOCALE_DEFAULT)

    return {
      kind: 'user',
      key: launchDarklyID,
      oloApplication: applicationName().toLowerCase() as Lowercase<BffContext.ApplicationTypes>,
      oloMarket: applicationConfig.COUNTRY.toLowerCase() as Lowercase<BffContext.Countries>,
      oloVersion: window.nativeAppVersion || applicationConfig.BUILD_BUILDNUMBER,
      oloLanguage: `${defaultLanguage}-${applicationConfig.COUNTRY}`,
    }
  }

  const config: ProviderConfig = {
    clientSideID: applicationConfig.LAUNCH_DARKLY_CLIENT_SIDE_ID,
    context: getContext(),
    options: {
      privateAttributes: ['email', 'ip'],
      sendEventsOnlyForVariation: true,
    },
    reactOptions: {
      useCamelCaseFlagKeys: false,
      sendEventsOnFlagRead: true,
    },
  }

  return <LDProvider {...config}>{children}</LDProvider>
}
