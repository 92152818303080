import { useFeaturesV2, useSdkAvailable } from '@dominos/hooks-and-hocs'
import React, { useEffect } from 'react'
import { getCountryConfig, isNativeApp } from '@dominos/business/functions'
import { AuthButton } from '@dominos/components'
import { AppleIcon } from '@dominos/res/images/icons/components'
import { useTranslation } from 'react-i18next'
import { AppleIdAuthFailure, AppleIdAuthSuccess } from './apple-button.interface'
import { AuthButtonProps, SocialLoginError } from '../auth-button-group.interface'

const AppleButton = ({ testID, loading, onReady, onToken, onError }: AuthButtonProps) => {
  const { t } = useTranslation('login')
  const { found } = useSdkAvailable('AppleID')
  const applicationConfig = getCountryConfig()
  const [appleAuthNativeEnabled] = useFeaturesV2('AppleAuthNativeApp')

  useEffect(() => {
    if (found) {
      window.AppleID?.auth.init({
        clientId: applicationConfig.APPLEID_CLIENT_ID,
        scope: 'name email',
        redirectURI: `${window.location.origin}${window.location.pathname}`,
        usePopup: true,
      })
    }
  }, [found])

  const appleIdSuccessCallback = ((data: CustomEvent<AppleIdAuthSuccess>) => {
    const success = data.detail
    const providerToken = success.authorization.id_token
    const additionalDetails = success.user
    const name = additionalDetails?.name && `${additionalDetails?.name?.firstName} ${additionalDetails?.name?.lastName}`

    onToken('Apple', providerToken, {
      ...additionalDetails,
      name,
    })
  }) as EventListener

  useEffect(() => {
    document.addEventListener('AppleIDSignInOnSuccess', appleIdSuccessCallback)

    if (appleAuthNativeEnabled) {
      const onMessageHandler = (event: MessageEvent) => {
        const appleAuthResponse = JSON.parse(event.data)
        const isAuthMessage = appleAuthResponse && appleAuthResponse.provider && appleAuthResponse.providerToken
        if (isAuthMessage) {
          onToken('Apple', appleAuthResponse.providerToken)
        }
      }

      if (isNativeApp()) {
        window.addEventListener('message', onMessageHandler)
      }

      return () => {
        document.removeEventListener('AppleIDSignInOnSuccess', appleIdSuccessCallback)
        if (isNativeApp()) {
          window.removeEventListener('message', onMessageHandler)
        }
      }
    }

    return () => document.removeEventListener('AppleIDSignInOnSuccess', appleIdSuccessCallback)
  }, [])

  const handleLogin = () => {
    onReady()

    window.AppleID?.auth.signIn().catch((detail: AppleIdAuthFailure) => {
      onError('Apple', SocialLoginError.Failed, detail.error)
    })
  }

  return found ? (
    <AuthButton
      testID={testID}
      backgroundColor={'#000000'}
      label={t('SignUpAppleTitle', { defaultValue: 'Sign in with Apple' })}
      loading={loading}
      onPress={handleLogin}
    >
      <AppleIcon fill={'#ffffff'} />
    </AuthButton>
  ) : null
}

export { AppleButton, AppleIdAuthSuccess }
