import _ from 'lodash'
import { AllowedFlagKeys, FeatureFlagType, FeatureReturnType } from './feature-flag.interface'
import { LDClient } from 'launchdarkly-react-client-sdk'
import { useLaunchDarklyFlags } from '@dominos/hooks-and-hocs/features/launch-darkly'
import { useMemo } from 'react'

type UseFeaturesReturnType<T extends AllowedFlagKeys[]> = { [K in keyof T]: FeatureReturnType<T[K]> }

export const useFeaturesV2 = <T extends AllowedFlagKeys[]>(...feats: T): UseFeaturesReturnType<T> => {
  const { flags } = useLaunchDarklyFlags()

  function getFeature<T extends AllowedFlagKeys>(feature: T): FeatureReturnType<T> {
    return flags[feature] as unknown as FeatureReturnType<T>
  }

  return useMemo(() => feats.map(getFeature) as UseFeaturesReturnType<T>, [flags])
}

export const getVariation = <T extends AllowedFlagKeys>(
  featureName: T,
  ldClient?: LDClient,
  features?: FeatureFlagType,
) => ldClient?.variation(featureName, features?.[featureName]) as FeatureReturnType<T>
