import { rootActions } from '@dominos/business'
import { isNativeApp, notifyNativeApp } from '@dominos/business/functions/native-app'
import { CollapsableTitledCard, Spinner } from '@dominos/components'
import {
  addUpWithHighPrecision,
  ErrorCardPopup,
  useCurrentOrderDetails,
  useIsValidatingBasket,
  useOrder,
  usePaymentsAvailableAtStore,
} from '@dominos/hooks-and-hocs'
import { RouteComponentProps } from '@reach/router'
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { DeliveryGuarantee } from './delivery-guarantee/delivery-guarantee'
import styles from './payment-container.less'
import PaymentMethodList from './payment-method-list'
import SplitPayment from './split-payment'
import { SplitPaymentOutstandingBalance } from './split-payment/split-payment'
import TipTheDriver from './tip-the-driver'
import { useCorrectOrderId } from './use-correct-order-id'
import { useIsFirstPayment } from './use-is-first-payment'
import usePaymentFeatureToggles from './use-payment-feature-toggles'
import { useSelectedPaymentSetting } from './use-selected-payment-setting'
import { Breadcrumb, getBreadcrumbTitlesMyOrder } from '@dominos/components/breadcrumb'
import { useRestartOrder } from '@dominos/components/footer/simple-footer/use-restart-order'

interface PaymentContainerProps extends RouteComponentProps {
  testID?: string
}

export const PaymentContainer = (props: PaymentContainerProps) => {
  const [outstandingBalance, setOutstandingBalance] = useState<SplitPaymentOutstandingBalance | undefined>()

  const { t } = useTranslation('checkout')
  const featureToggles = usePaymentFeatureToggles()
  const { basketHeaderData } = useCurrentOrderDetails()
  const { orderId } = useCorrectOrderId()
  const { isValidating, waitForValidation } = useIsValidatingBasket()
  const dispatch = useDispatch()
  const { fetchOrder, order } = useOrder()
  const { orderTotal, firstPayment } = useIsFirstPayment(order)
  const { paymentSettings = [], loading } = usePaymentsAvailableAtStore(
    basketHeaderData?.storeNo,
    basketHeaderData?.serviceMethod,
    basketHeaderData?.serviceMethodSubType,
    basketHeaderData?.time ?? undefined,
    !!outstandingBalance,
  )
  const { selectedPaymentSetting, setSelectedPaymentSetting, edenredAuthCode } = useSelectedPaymentSetting(
    paymentSettings,
    !!outstandingBalance,
  )

  const resetOrder = useRestartOrder({ report: true, fos: true })

  const basketData = useSelector((state: RootReducer) => state.basketReducer.basket)

  const showDeliveryGuarantee = useMemo(
    () => featureToggles.deliveryGuaranteeEnabled && basketHeaderData?.serviceMethodSubType !== 'RobotDelivery',
    [featureToggles.deliveryGuaranteeEnabled, basketHeaderData?.serviceMethodSubType],
  )

  const onOutstandingBalance = (balance: SplitPaymentOutstandingBalance) => {
    setOutstandingBalance(balance)
  }

  useEffect(() => {
    fetchOrder(orderId)
  }, [orderId])

  useEffect(() => {
    if (orderTotal && firstPayment) {
      setOutstandingBalance({
        orderTotal: orderTotal,
        processedPaymentName: firstPayment.paymentMethod,
        orderOutstandingBalance: addUpWithHighPrecision(orderTotal, -firstPayment.amount),
      })
    }
  }, [orderTotal, firstPayment])

  useEffect(() => {
    if (selectedPaymentSetting) {
      const { paymentMethod, providerCode } = selectedPaymentSetting
      dispatch(rootActions.updatePaymentMethod({ paymentMethod, providerCode }))
      waitForValidation()
    }
  }, [selectedPaymentSetting])

  useEffect(() => {
    notifyNativeApp('payment')
  }, [])

  const onPaymentMethodSelected = (event: ChangeEvent<HTMLInputElement>) => {
    const [methodId, paymentMethod, providerCode, donationEnabledStr] = event.target.value.split(',') as [
      string,
      BffContext.PaymentMethods,
      BffContext.PaymentProviders,
      string,
    ]

    if (methodId && paymentMethod && providerCode) {
      const donationEnabled = donationEnabledStr === 'true'
      // @ts-ignore
      setSelectedPaymentSetting({ methodId, paymentMethod, providerCode, donationEnabled })
    }
  }

  const paymentMethodTitle =
    outstandingBalance?.orderOutstandingBalance && featureToggles.splitPaymentsEnabled
      ? t('PayBalanceUsing')
      : t('Payment Method')

  const onErrorPopupClose = isNativeApp() ? () => notifyNativeApp('error') : resetOrder

  return (
    <>
      <Breadcrumb
        testID='payment.breadcrumb'
        entities={getBreadcrumbTitlesMyOrder(basketData.total, basketData.lines.length)}
        flowDepth={2}
        textNamespace={['checkout', 'basket']}
      />
      <TipTheDriver testID={'tip-the-driver'} store={order?.store} />
      {showDeliveryGuarantee && <DeliveryGuarantee testID={'delivery-guarantee'} />}

      {featureToggles.splitPaymentsEnabled && outstandingBalance && (
        <SplitPayment
          testID={'split-payment'}
          outstandingBalance={outstandingBalance}
          paymentMethod={outstandingBalance.processedPaymentName}
        />
      )}
      <CollapsableTitledCard
        primaryTitle={paymentMethodTitle}
        startCollapsed={false}
        noPadding={true}
        testID={'payment-container-card'}
        static
      >
        <div className={styles.titledCardContent}>
          <div role='tablist' className={loading ? styles.loading : undefined} onChange={onPaymentMethodSelected}>
            {loading ? (
              <Spinner testID='payments-spinner' color={'rgb(0, 122, 175)'} />
            ) : (
              <PaymentMethodList
                orderId={orderId}
                serviceMethod={basketHeaderData?.serviceMethod}
                paymentSettings={paymentSettings}
                selectedPaymentSetting={selectedPaymentSetting}
                isValidating={isValidating}
                featureToggles={featureToggles}
                outstandingBalance={outstandingBalance}
                onOutstandingBalance={onOutstandingBalance}
                edenredAuthCode={edenredAuthCode}
              />
            )}
          </div>
        </div>
      </CollapsableTitledCard>
      {!loading && paymentSettings.length === 0 && (
        <ErrorCardPopup
          testID={`payment-container.error-popup`}
          title={t('NoPaymentAvailableMessageTitle', {
            defaultValue: 'No Payment Method',
          })}
          message={t('NoPaymentAvailableMessage', {
            defaultValue: 'Payment option is not available',
          })}
          confirmLabel={t('NoPaymentAvailableMessageConfirmLabel', {
            defaultValue: 'Okay',
          })}
          onClose={onErrorPopupClose}
        />
      )}
    </>
  )
}
